import * as React from 'react'
import { useDispatch } from 'react-redux'
import LoadingButton from '@mui/lab/LoadingButton'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'
import { SliderPicker } from 'react-color'

import updateCategory from '../../../../../../api/categories/update'
import deleteCategory from '../../../../../../api/categories/delete'
import { addSnackbar } from '../../../../../../redux/snackbars'

export default function EditCategoryDailog({
  open,
  handleClose,
  category,
  onSubmit
}) {
  const dispatch = useDispatch()

  const [submitting, setSubmitting] = React.useState(false)
  const [deleting, setDeleting] = React.useState(false)

  const [name, setName] = React.useState('')
  const [color, setColor] = React.useState('')

  const handleDelete = () => {
    setDeleting(true)
    deleteCategory(category.uuid).then(() => {
      setDeleting(false)
      dispatch(addSnackbar({id: 'deletecategorysuccess', text: 'Successfully deleted.'}))
      setName('')
      setColor('')
      handleClose()
      onSubmit && onSubmit()
    }).catch(err => {
      console.error(err)
      setDeleting(false)
      dispatch(addSnackbar({id: 'deletecategoryfail', text: 'Failed to delete category.'}))
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setSubmitting(true)
    updateCategory(category.uuid, {name, color})
      .then(() => {
        setSubmitting(false)
        dispatch(addSnackbar({id: 'updatecategorysuccess', text: 'Updated category.'}))
        setName('')
        setColor('')
        handleClose()
        onSubmit && onSubmit()
      })
      .catch(err => {
        console.error(err)
        setSubmitting(false)
        dispatch(addSnackbar({id: 'updatecategoryfail', text: 'Failed to update category.'}))
      })
  }

  React.useEffect(() => {
    if (!category) return
    setName(category.name)
    setColor(category.color)
  }, [category])

  return (
    <Box component="form">
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Edit Category
        </DialogTitle>
        <DialogContent>
          <TextField
            name="name"
            fullWidth
            sx={{my: 3}}
            id="name"
            label="Name"
            value={name}
            onChange={e => setName(e.target.value)}
          />

          <Box sx={{py: 2, px: 1}}>
            <SliderPicker
              color={{hex: color}}
              onChange={color => setColor(color.hex)}
            />
          </Box>

          <LoadingButton
            sx={{mt: 3}}
            fullWidth
            loading={deleting}
            onClick={handleDelete}
            variant="outlined"
            color="error"
          >
            Delete Category
          </LoadingButton>
        </DialogContent>
        <DialogActions>
          <LoadingButton loading={submitting} onClick={handleSubmit}>Save</LoadingButton>
          <LoadingButton onClick={handleClose}>Cancel</LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
