import * as React from 'react'

import Paper from '@mui/material/Paper'
import Checkbox from '@mui/material/Checkbox'
import Table from '@mui/material/Table'
import Divider from '@mui/material/Divider'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import Pagination from '@mui/material/Pagination'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import FilterList from '@mui/icons-material/FilterList'

import CreateMappingDialog from '../CreateMappingDialog'

import FilterContainer from '../../../../../../components/FilterContainer'
import centsToDollarString from '../../../../../../helpers/money/dollarsToDollarString'
import BulkCategoriseDialog from '../BulkCategoriseDialog'

const PER_PAGE = 50

const entriesMatch = (a, b) => (
  a.description === b.description
  && a.date === b.date
  && a.amount === b.amount
)

export default function MissesTable({
  entries=[],
  onMappingCreated,
}) {
  const [ page, setPage ] = React.useState(1)

  const [ initialMapping, setInitialMapping ] = React.useState(null)
  const [ createMappingDialogOpen, setCreateMappingDialogOpen ] = React.useState(false)
  const [ bulkCategoriseDialogOpen, setBulkCategoriseDialogOpen ] = React.useState(false)
  const [ descriptionFilter, setDescriptionFilter ] = React.useState('')
  const [selected, setSelected] = React.useState([])

  const filteredEntries = entries.filter(e => (!descriptionFilter || (e.description.toLowerCase().includes(descriptionFilter.toLowerCase()))))

  const totalPages = Math.ceil(filteredEntries.length / PER_PAGE)

  const filteredPagedEntries = filteredEntries.slice((page-1) * PER_PAGE, page * PER_PAGE)

  React.useEffect(() => {
    if (page > totalPages) setPage(1)
  }, [page, totalPages])

  if (entries.length === 0) return <Typography variant="p">No uncategorised transactions.</Typography>

  const isSelected = (entry) => {
    return (selected.filter(s => (entriesMatch(s, entry))).length > 0)
  }

  const handleCheckEntry = (entry) => {
    if (isSelected(entry)) {
      setSelected(selected.filter(s => (!entriesMatch(s, entry))))
    } else {
      setSelected([...selected, entry])
    }
  }

  const handleMappingCreated = () => {
    setSelected([])
    onMappingCreated()
    setBulkCategoriseDialogOpen(false)
  }

  return (
    <Paper elevation={3} style={{overflowX: 'scroll'}}>
      <BulkCategoriseDialog
        open={bulkCategoriseDialogOpen}
        handleClose={() => handleMappingCreated()}
        entries={selected}
      />

      <FilterContainer>
        <FilterList sx={{ fontSize: 22 }} />
        <b style={{fontSize: '13px', paddingLeft: '0.15rem', paddingRight: '1rem'}}>Filter</b>

        <TextField
          name="descriptionGlob"
          fullWidth
          id="descriptionGlob"
          sx={{ml: 2}}
          label="Search description"
          value={descriptionFilter}
          onChange={e => setDescriptionFilter(e.target.value)}
        />
      </FilterContainer>

      <CreateMappingDialog
        initialMapping={initialMapping}
        open={createMappingDialogOpen}
        handleClose={() => setCreateMappingDialogOpen(false)}
        onSubmit={() => {setCreateMappingDialogOpen(false) ; handleMappingCreated()}}
      />

      {totalPages > 1 &&
      <Pagination
        count={totalPages}
        page={page}
        // sx={{pt: 2}}
        onChange={(_, v) => setPage(v)}
      />}

      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left" />
            <TableCell align="left"><b>Date</b></TableCell>
            <TableCell align="left"><b>Description</b></TableCell>
            <TableCell align="left"><b>Amount</b></TableCell>
            <TableCell align="left" />
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredPagedEntries.map((entry, i) => (
            <TableRow
              key={`${entry.description}${entry.amount}${entry.castedDate}${i}`}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  checked={isSelected(entry)}
                  onChange={() => handleCheckEntry(entry)}
                  // inputProps={{
                  //   'aria-labelledby': labelId,
                  // }}
                />
              </TableCell>
              <TableCell align="left">{entry.castedDate.format('DD/MM/YYYY')}</TableCell>
              <TableCell align="left" style={{whiteSpace: 'pre-wrap'}}>{entry.description}</TableCell>
              <TableCell align="left">{centsToDollarString(Math.abs(entry.debit))}</TableCell>
              <TableCell align="right">
                {!isSelected(entry) &&
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => {setInitialMapping({descriptionGlob: entry.description, matchAmount: Math.abs(entry.debit)}) ; setCreateMappingDialogOpen(true)}}
                >
                  Create&nbsp;Rule
                </Button>}

                {isSelected(entry) &&
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => setBulkCategoriseDialogOpen(true)}
                >
                  Categorise
                </Button>}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  )
}
