import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import LoadingButton from '@mui/lab/LoadingButton'
import Alert from '@mui/material/Alert'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import FormGroup from '@mui/material/FormGroup'
import Breadcrumbs from '@mui/material/Breadcrumbs'

import HeadingContainer from '../../../../components/HeadingContainer'
import Copyright from '../../../../components/Copyright'

import updateUser from '../../../../api/users/update'
import passwordToValidationErrorString from '../../../../helpers/validation/passwordToValidationErrorString'

import { addSnackbar } from '../../../../redux/snackbars'

export default function Profile() {
  const dispatch = useDispatch()
  const currentUserProfile = useSelector(state => state.currentUserProfile)

  const [errorMessage, setErrorMessage] = React.useState('')

  const [oldPassword, setOldPassword] = React.useState('')
  const [newPassword, setNewPassword] = React.useState('')
  const [confirmNewPassword, setConfirmNewPassword] = React.useState('')

  const [isSaving, setSaving] = React.useState(false)

  const handleSave = () => {
    setSaving(true)
    updateUser(currentUserProfile.uuid, {
      oldPassword,
      newPassword
    }).then(user => {
      setOldPassword('')
      setNewPassword('')
      setConfirmNewPassword('')

      setErrorMessage('')

      setSaving(false)
      dispatch(addSnackbar({id: 'passwordupdated', text: 'Successfully changed password.'}))
    }).catch(err => {
      console.error(err)
      setSaving(false)
      setErrorMessage((err.response && err.response.data && err.response.data.error) || err.message)
    })
  }

  const validationErrorMessage = passwordToValidationErrorString(newPassword, confirmNewPassword)

  return (
    <div>
      {errorMessage &&
      <Stack sx={{ width: '100%' }} spacing={2}>
        <Alert severity="error">{errorMessage}</Alert>
      </Stack>}

      <HeadingContainer>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
        >
          Change Password
        </Typography>
      </HeadingContainer>

      <Divider />

      <Container maxWidth="sm">
        <FormGroup>
          <Box component="form" noValidate>
            <Grid sx={{ mt: 2 }}>
              <TextField
                autoComplete="password"
                name="oldPassword"
                required
                type="password"
                fullWidth
                id="oldPassword"
                value={oldPassword || ''}
                label="Existing Password"
                onChange={e => setOldPassword(e.target.value)}
              />
            </Grid>

            <Grid sx={{ mt: 2 }}>
              <TextField
                autoComplete="password"
                name="newPassword"
                required
                type="password"
                fullWidth
                id="newPassword"
                value={newPassword || ''}
                label="New Password"
                onChange={e => setNewPassword(e.target.value)}
              />
            </Grid>

            <Grid sx={{ mt: 2 }}>
              <TextField
                autoComplete="password"
                name="confirmNewPassword"
                required
                type="password"
                fullWidth
                id="confirmNewPassword"
                value={confirmNewPassword || ''}
                label="Confirm New Password"
                onChange={e => setConfirmNewPassword(e.target.value)}
              />
            </Grid>
          </Box>

          <Grid>
            {validationErrorMessage &&
            <Typography sx={{ mt: 2 }} color="error">
              {validationErrorMessage}
            </Typography>}

            <LoadingButton
              fullWidth
              onClick={handleSave}
              disabled={!oldPassword || !newPassword || (newPassword !== confirmNewPassword)}
              loading={isSaving}
              sx={{ mt: 2 }}
              variant="contained"
            >
              Save
            </LoadingButton>
          </Grid>
        </FormGroup>
      </Container>

      <Copyright sx={{ pt: 4 }} />
    </div>
  );
}
