import * as React from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'

import { bankList } from '../../helpers/csvFile/presetImportOptions'

export default function PresetImportOptionsPicker({
  value,
  onChange
}) {
  return (
    <FormControl fullWidth>
      <InputLabel id="bank">Select Bank</InputLabel>
      <Select
        fullWidth
        labelId="bank"
        value={value}
        label="Select Bank"
        onChange={e => onChange(e.target.value)}
      >
        <MenuItem value="custom">
          <Box display="flex" alignItems="center">
            <span style={{opacity: 0, height: '1.5rem'}} />
            <Typography>Custom import options</Typography>
          </Box>
        </MenuItem>
        {bankList && bankList.map(bank => (
          <MenuItem key={bank.id} value={bank.id}>
            <Box display="flex" alignItems="center">
              <img alt={`Bank ${bank.name}`} src={`/img/bank-icons/${bank.imageName}`} style={{borderRadius: '50%', height: '1.5rem', marginRight: '0.5rem'}} />
              <Typography>{bank.name}</Typography>
            </Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
