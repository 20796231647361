import * as React from 'react'
import { useSelector } from 'react-redux'
import { Outlet, useNavigate } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
// import MenuIcon from '@mui/icons-material/Menu'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { MainListItems } from './listItems'

import AppBarItems from '../../components/AppBar/AppBarItems'

import AppBar from '../../components/AppBarWithDrawer/AppBar'
import Drawer from '../../components/AppBarWithDrawer/Drawer'

import mdTheme from '../../lib/mdTheme'

export default function Account() {
  const navigate = useNavigate()
  const currentUserProfile = useSelector(state => state.currentUserProfile)
  const [open, setOpen] = React.useState(false)
  const toggleDrawer = () => {
    setOpen(!open)
  }

  React.useEffect(() => {
    if (!currentUserProfile)
      return navigate('/app/signin')
  })

  const hasActiveStripeSubscription = currentUserProfile && currentUserProfile.hasActiveStripeSubscription

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex' }}>

        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <ChevronRightIcon />
            </IconButton>

            <Box sx={{ flexGrow: 1 }}/>
            {/* <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Account
            </Typography> */}


            {!hasActiveStripeSubscription &&
            <Button
              style={{color: 'white', borderColor: 'white'}} sx={{mr: 2}}
              variant="outlined" onClick={() => navigate('/app/account/subscription')}
            >
              Trial Mode
            </Button>}

            <AppBarItems />
          </Toolbar>
        </AppBar>

        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>

          <Divider />

          <List><MainListItems /></List>
        </Drawer>

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
          className="mobile-have-min-width"
        >
          <Toolbar />

          <Outlet />
        </Box>
      </Box>
    </ThemeProvider>
  )
}
