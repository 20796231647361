const filterByDates = (startDate, endDate) => {
  return entry => {
    if (!entry.castedDate) return true
    if (startDate && (entry.castedDate < startDate)) return false
    if (endDate && (entry.castedDate > endDate)) return false
    return true
  }
}

export default filterByDates
