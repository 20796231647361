import * as React from 'react'

import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import CircleIcon from '@mui/icons-material/Circle'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import entriesToWeeklyAveragesTable from '../../../../../../helpers/charts/entriesToWeeklyAveragesTable'
import dollarsToDollarString from '../../../../../../helpers/money/dollarsToDollarString'

export default function WeeklyAverages({
  categories,
  filteredMatchedEntries=[]
}) {
  const tableRows = entriesToWeeklyAveragesTable(filteredMatchedEntries, categories)

  return (
    <Paper elevation={3}>
      <Box sx={{p: 2}}>
        <Typography variant="p">
          Weekly average spend per category. Assumes every week with data is a full week.
        </Typography>
      </Box>
      <Divider />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left"><b>Category</b></TableCell>
            <TableCell align="left"><b>Spend</b></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableRows.map((row, i) => (
            <TableRow
              key={`${row.categoryUuid}`}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left">
                <Box display="flex" alignItems="center">
                  {row.categoryColor &&
                  <CircleIcon style={{color: row.categoryColor}} sx={{mr: 2}} />}
                  <span>{row.categoryName}</span>
                </Box>
              </TableCell>
              <TableCell align="left">{dollarsToDollarString(row.amount)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  )
}
