import { createSlice } from '@reduxjs/toolkit'

export const csvImportOptionsSlice = createSlice({
  name: 'csvImportOptions',
  initialState: null,
  reducers: {
    setCsvImportOptions: (state, action) => {
      return action.payload
    },
  },
})

export const { setCsvImportOptions } = csvImportOptionsSlice.actions

export default csvImportOptionsSlice.reducer
