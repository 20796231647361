import moment from 'moment'

export default function findErrorsInCSVImportSettings(
  csvFileRows,
  csvImportOptions
) {
  let {
    dateColumnName,
    descriptionColumnName,
    amountColumnName,

    dateColumnIndex,
    descriptionColumnIndex,
    amountColumnIndex,

    dateFormat,

    hasHeaderRow,
  } = (csvImportOptions || {})

  const csvFileHeaderRow = csvFileRows && csvFileRows[0]
  const csvFileDataRow = csvFileRows && csvFileRows[1]

  if (hasHeaderRow)
    dateColumnIndex = csvFileHeaderRow && csvFileHeaderRow.indexOf(dateColumnName && dateColumnName.toLowerCase())
  const dateExample = (dateColumnIndex >= 0) && csvFileDataRow && csvFileDataRow[dateColumnIndex]

  const dateFormatValid = dateFormat && dateExample && (new moment(dateExample, dateFormat).format(dateFormat) === dateExample)
  const dateColumnFound = csvFileHeaderRow && csvFileHeaderRow.includes(dateColumnName && dateColumnName.toLowerCase())
  const descriptionColumnFound = csvFileHeaderRow && csvFileHeaderRow.includes(descriptionColumnName && descriptionColumnName.toLowerCase())
  const amountColumnFound = csvFileHeaderRow && csvFileHeaderRow.includes(amountColumnName && amountColumnName.toLowerCase())

  const numCols = (csvFileHeaderRow || []).length

  const columnIndexValid = i => (!isNaN(i) && i >= 0 && i < numCols)

  const dateColumnIndexValid = columnIndexValid(dateColumnIndex)
  const descriptionColumnIndexValid = columnIndexValid(descriptionColumnIndex)
  const amountColumnIndexValid = columnIndexValid(amountColumnIndex)

  const importErrors = []

  if (!dateFormatValid) importErrors.push('Date format does not match.')

  if (hasHeaderRow) {
    if (!dateColumnFound) importErrors.push('Date column not found.')
    if (!descriptionColumnFound) importErrors.push('Description column not found.')
    if (!amountColumnFound) importErrors.push('Amount column not found.')
  } else {
    if (!dateColumnIndexValid) importErrors.push('Date column is out of range.')
    if (!descriptionColumnIndexValid) importErrors.push('Description column is out of range.')
    if (!amountColumnIndexValid) importErrors.push('Amount column is out of range.')
  }

  return {
    dateColumnFound,
    descriptionColumnFound,
    amountColumnFound,

    dateColumnIndexValid,
    descriptionColumnIndexValid,
    amountColumnIndexValid,

    dateFormatValid,

    importErrors,
  }
}
