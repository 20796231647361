import * as React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import WarningIcon from '@mui/icons-material/Warning'
import CheckIcon from '@mui/icons-material/Check'

function Tip({
  text,
  success
}) {
  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      {!success && <WarningIcon color="error" />}
      {success && <CheckIcon color="success" />}

      <Typography variant="p" sx={{ml:2}} component="span">
        {text}
      </Typography>
    </Box>
  )
}

export default function ImportErrors({
  errors=[],
  successes=[]
}) {
  return (
    <Box>
      {errors.map(message => (
        <Tip key={message} text={message} />
      ))}
      {successes.map(message => (
        <Tip key={message} text={message} success />
      ))}
    </Box>
  )
}
