import * as React from 'react'
import { useNavigate, useLocation } from "react-router-dom"
import ListItem from '@mui/material/ListItem'
import Box from '@mui/material/Box'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import ListSubheader from '@mui/material/ListSubheader'
import PersonIcon from '@mui/icons-material/Person'

const NavListItem = (props) => {
  const {
    path
  } = props

  const navigate = useNavigate()
  const location = useLocation()

  return (
    <ListItem selected={location.pathname.startsWith(path)} button onClick={() => navigate(path)}>
      {props.children}
    </ListItem>
  )
}

export const MainListItems = () => {
  return (
    <Box>
      <NavListItem path='/app/admin/users'>
        <ListItemIcon>
          <PersonIcon />
        </ListItemIcon>
        <ListItemText primary="Users" />
      </NavListItem>
    </Box>
  )
}

export const secondaryListItems = (
  <div>
    <ListSubheader inset>System</ListSubheader>
  </div>
)
