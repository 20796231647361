const upbank = {
  dateColumnName: 'settled date',
  descriptionColumnName: 'description',
  amountColumnName: 'Total (AUD)',

  dateColumnIndex: undefined,
  descriptionColumnIndex: undefined,
  amountColumnIndex: undefined,

  dateFormat: 'YYYY-MM-DD',

  negativeValuesOnly: true,
  hasHeaderRow: true,
}

export default upbank
