import * as React from 'react'
import { useDispatch } from 'react-redux'
import LoadingButton from '@mui/lab/LoadingButton'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import CircleIcon from '@mui/icons-material/Circle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'
import Grid from '@mui/material/Grid'
import CircularProgress from '@mui/material/CircularProgress'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

import bulkCreateMapping from '../../../../../../api/mappings/bulkCreate'
import searchCategories from '../../../../../../api/categories/search'
import { addSnackbar } from '../../../../../../redux/snackbars'

export default function BulkCategoriseDialog({
  open,
  handleClose,
  entries
}) {
  const dispatch = useDispatch()

  const [submitting, setSubmitting] = React.useState(false)

  const [categoriesLoading, setCategoriesLoading] = React.useState(true)
  const [categories, setCategories] = React.useState(null)

  const [categoryUuid, setCategoryUuid] = React.useState('')

  React.useEffect(() => {
    searchCategories().then((categories) => {
      setCategoriesLoading(false)
      setCategories(categories)
    }).catch(err => {
      console.error(err)
      setCategoriesLoading(false)
      dispatch(addSnackbar({id: 'categorysearchfail', text: 'Failed to get categories.'}))
    })
  }, [])

  const handleSubmit = () => {
    setSubmitting(true)
    bulkCreateMapping({
      categoryUuid,
      entries: entries.map(e => ({
        descriptionGlob: e.description,
        matchAmount: e.amount,
      }))
    }).then(() => {
      setSubmitting(false)
      dispatch(addSnackbar({id: 'bulkcreatemappingsuccess', text: 'Created rules.'}))
      handleClose()
    }).catch(err => {
      console.error(err)
      setSubmitting(false)
      dispatch(addSnackbar({id: 'bulkcreatemappingfail', text: 'Failed to create rules.'}))
    })
  }

  return (
    <Box component="form">
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Bulk Categorise
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={2}>

            <Grid item xs={12}>
              <Typography variant="p">
                All selected transactions will be categorised.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              {categoriesLoading && <CircularProgress />}
              {!categoriesLoading &&
              <FormControl fullWidth>
                <InputLabel id="category">Category</InputLabel>
                <Select
                  fullWidth
                  required
                  labelId="category"
                  value={categoryUuid}
                  label="Category"
                  onChange={e => setCategoryUuid(e.target.value)}
                >
                  {categories && categories.map(c => (
                    <MenuItem key={c.uuid} value={c.uuid}>
                      <Box display="flex" alignItems="center">
                        {c.color &&
                        <CircleIcon style={{color: c.color}} sx={{mr: 2}} />}
                        <span>{c.name}</span>
                      </Box>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>}
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <LoadingButton
            disabled={!categoryUuid}
            loading={submitting}
            onClick={handleSubmit}
            variant="contained"
          >
            Bulk Categorise
          </LoadingButton>
          <LoadingButton onClick={handleClose}>Cancel</LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
