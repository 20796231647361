import * as React from 'react'

import Paper from '@mui/material/Paper'
import CircularProgress from '@mui/material/CircularProgress'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import Pagination from '@mui/material/Pagination'
import TableRow from '@mui/material/TableRow'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import CircleIcon from '@mui/icons-material/Circle'

import FilterList from '@mui/icons-material/FilterList'

import FilterContainer from '../../../../../../components/FilterContainer'
import centsToDollarString from '../../../../../../helpers/money/dollarsToDollarString'

const PER_PAGE = 50

export default function MatchesTable({
  entries=[],
  categoriesLoading,
  categories,
}) {
  const [ page, setPage ] = React.useState(1)
  const [ categoryUuid, setCategoryUuid ] = React.useState('')
  const [ descriptionFilter, setDescriptionFilter ] = React.useState('')

  const filteredEntries = entries
  .filter(e => (!categoryUuid || ((e.category && e.category.uuid) === categoryUuid)))
  .filter(e => (!descriptionFilter || (e.description.toLowerCase().includes(descriptionFilter.toLowerCase()))))

  const totalPages = Math.ceil(filteredEntries.length / PER_PAGE)

  const filteredPagedEntries = filteredEntries.slice((page-1) * PER_PAGE, page * PER_PAGE)

  React.useEffect(() => {
    if (page > totalPages) setPage(1)
  }, [page, totalPages])

  if (entries.length === 0) return <Typography variant="p">No categorised transactions.</Typography>

  return (
    <Paper elevation={3} style={{overflowX: 'scroll'}}>

      <FilterContainer>
        <FilterList sx={{ fontSize: 22 }} />
        <b style={{fontSize: '13px', paddingLeft: '0.15rem', paddingRight: '1rem'}}>Filter</b>

        {categoriesLoading && <CircularProgress />}
        {!categoriesLoading &&
        <FormControl fullWidth>
          <InputLabel id="category">Category</InputLabel>
          <Select
            fullWidth
            labelId="category"
            value={categoryUuid}
            label="Category"
            onChange={e => setCategoryUuid(e.target.value)}
          >
            {categories && categories.map(c => (
              <MenuItem key={c.uuid} value={c.uuid}>
                <Box display="flex" alignItems="center">
                  {c.color &&
                  <CircleIcon style={{color: c.color}} sx={{mr: 2}} />}
                  <span>{c.name}</span>
                </Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>}

        <TextField
          name="descriptionGlob"
          fullWidth
          id="descriptionGlob"
          sx={{ml: 2}}
          label="Search description"
          value={descriptionFilter}
          onChange={e => setDescriptionFilter(e.target.value)}
        />
      </FilterContainer>

      {totalPages > 1 &&
      <Pagination
        count={totalPages}
        page={page}
        onChange={(_, v) => setPage(v)}
      />}

      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left"><b>Date</b></TableCell>
            <TableCell align="left"><b>Description</b></TableCell>
            <TableCell align="left"><b>Category</b></TableCell>
            <TableCell align="left"><b>Amount</b></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredPagedEntries.map((entry, i) => (
            <TableRow
              key={`${entry.castedDate}${entry.description}${entry.amount}${i}`}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left">{entry.castedDate.format('DD/MM/YYYY')}</TableCell>
              <TableCell align="left" style={{whiteSpace: 'pre-wrap'}}>{entry.description}</TableCell>
              <TableCell align="left">{entry.category && entry.category.name}</TableCell>
              <TableCell align="left">{centsToDollarString(Math.abs(entry.debit))}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  )
}
