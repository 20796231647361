import * as React from 'react'
import { useDispatch } from 'react-redux'
import { SliderPicker } from 'react-color'
import LoadingButton from '@mui/lab/LoadingButton'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'

import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'

import createCategory from '../../../../../../api/categories/create'
import { addSnackbar } from '../../../../../../redux/snackbars'

export default function CreateCategory({
  onCreate
}) {
  const dispatch = useDispatch()

  const [submitting, setSubmitting] = React.useState(false)

  const [name, setName] = React.useState('')
  const [color, setColor] = React.useState('')

  const handleSubmit = (event) => {
    event.preventDefault()

    setSubmitting(true)

    createCategory({name, color})
      .then(() => {
        setSubmitting(false)
        dispatch(addSnackbar({id: 'createcategorysuccess', text: 'Created category.'}))
        onCreate && onCreate()
        setName('')
      })
      .catch(err => {
        console.error(err)
        setSubmitting(false)
        dispatch(addSnackbar({id: 'createcategoryfail', text: 'Failed to create category.'}))

      })
  }

  return (
    <Paper elevation={3} sx={{py: 2, px: 2}}>
      <Typography variant="h5" component="h5" sx={{mb: 3}}>
        Add Category
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            name="name"
            required
            fullWidth
            id="name"
            label="Name"
            onChange={e => setName(e.target.value)}
          />

          <Box sx={{pt: 4, pb: 2, px: 1}}>
            <SliderPicker
              color={{hex: color}}
              onChange={color => setColor(color.hex)}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <LoadingButton
            fullWidth
            disabled={!name}
            onClick={handleSubmit}
            loading={submitting}
            variant="contained"
          >
            Create
          </LoadingButton>
        </Grid>
      </Grid>
    </Paper>
  )
}
