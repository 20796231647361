const westpac = {
  dateColumnName: 'Date',
  descriptionColumnName: 'Narrative',
  amountColumnName: 'Debit Amount',

  dateColumnIndex: undefined,
  descriptionColumnIndex: undefined,
  amountColumnIndex: undefined,

  dateFormat: 'DD/MM/YYYY',

  negativeValuesOnly: false,
  hasHeaderRow: true,
}

export default westpac
