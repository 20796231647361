/* eslint-disable no-template-curly-in-string */
import * as React from 'react'

// import Highcharts from 'highcharts'
// import HighchartsReact from 'highcharts-react-official'
import { Pie } from 'react-chartjs-2'
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js'

import Box from '@mui/material/Box'

import { categories } from '../exampleData'
import pieChartJSOptions from '../../../../helpers/charts/pieChartJSOptions'

Chart.register(ArcElement, Tooltip, Legend)

const pieDataChartJS = {
  labels: categories.map(c => (c.name)),
  datasets: [{
    label: 'Expenses',
    data: categories.map(c => (c.y)),
    backgroundColor: categories.map(c => (c.color)),
    hoverOffset: 4,
    borderWidth: 1
  }]
}

const options = pieChartJSOptions(pieDataChartJS)
options.plugins.legend.display = false

export default function ExampleChartSmall() {
  return (
    <Box
      style={{width: '100%', height: '30vh', maxHeight: '250px'}}
      sx={{px: 2}}
    >
      <Pie
        data={pieDataChartJS}
        options={options}
      />
    </Box>
  )
}
