import * as React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Typist from 'react-typist'

import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import { ThemeProvider } from '@mui/material/styles'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Avatar from '@mui/material/Avatar'

import SaveIcon from '@mui/icons-material/Save'
import LanguageIcon from '@mui/icons-material/Language'
import SecurityIcon from '@mui/icons-material/Security'

import AppBar from '../../components/AppBar'
import Footer from '../../components/Footer'
import SubscriptionItem from '../../components/SubscriptionItem'
import NumberCircle from './components/NumberCircle'
import BankListPickerExample from './components/BankListPickerExample'
// import CategoryList from './components/CategoryList'
import RulesList from './components/RulesList'
import ExampleChart from './components/ExampleChart'
import ExampleChartSmall from './components/ExampleChartSmall'

import mdTheme from '../../lib/mdTheme'

const TYPIST_1 = 'See where your money\'s going.'
const TYPIST_2 = 'Take control of your finances.'
const TYPIST_3 = 'Stick to your budget.'

export default function Home() {
  const navigate = useNavigate()

  const currentUserProfile = useSelector((state) => state.currentUserProfile)
  const [ typing, setTyping ] = React.useState(true)

  React.useEffect(() => {
    if (!typing) setTyping(true)
  }, [typing])

  return (
    <ThemeProvider theme={mdTheme}>
      <Box>
        <AppBar invert />

        <Toolbar />

        <Box className='home-bg' sx={{pb: 10}}>
          <Container maxWidth="md" sx={{pb: 10, pt: 18}} style={{marginTop: '-64px'}}>
            <Grid container spacing={2} alignItems="center" wrap="wrap-reverse">
              <Grid item xs={12} md={6}>
                <Paper sx={{py: 2}}>
                  <Box display="flex" flexDirection="column" alignItems="center">
                    <ExampleChartSmall />

                    <Typography variant="p" textAlign="center" sx={{px: 4}}>
                      Understand your expenses though data visualisation.
                    </Typography>
                  </Box>
                </Paper>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography
                  component="h1"
                  variant="h2"
                  align="center"
                  color="white"
                  gutterBottom
                  style={{
                    fontWeight: 800
                  }}
                  sx={{mt: 2}}
                >
                  Budget Analyser
                </Typography>

                <Typography variant="h5" align="center" color="text.secondary" sx={{mb: 5}}>
                  {typing &&
                  <Typist cursor={{blink: true}} onTypingDone={() => setTyping(false)}>
                    <span>{TYPIST_1}</span>
                    <Typist.Backspace count={TYPIST_1.length} delay={5000} />
                    <span>{TYPIST_2}</span>
                    <Typist.Backspace count={TYPIST_2.length} delay={5000} />
                    <span>{TYPIST_3}</span>
                    <Typist.Backspace count={TYPIST_3.length} delay={5000} />
                  </Typist>}
                  {!typing &&
                  <Typist cursor={{blink: true}} onTypingDone={() => setTyping(false)}>
                    <span>&nbsp;</span>
                  </Typist>}
                </Typography>

                <Stack
                  sx={{mb: 2}}
                  direction="row"
                  spacing={2}
                  justifyContent="center"
                >
                  <Button
                    onClick={() => currentUserProfile ? navigate('/app/budget/help') : navigate('/app/signin')}
                    variant="contained"
                  >
                    {currentUserProfile ? 'Enter account' : 'Start Now'} <ArrowForwardIosIcon style={{fontSize: '0.75rem'}} sx={{ml: 1}} />
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Container>
        </Box>

        <Divider />

        <Container maxWidth="md">
          <Grid container spacing={3} sx={{mt: 10}} display="flex" alignItems="center">
            <Grid item xs={12} md={6}>
              <Box
                sx={{mb: 2}}
                display="flex"
                justifyContent="center"
              >
                <NumberCircle number="1" color="#c42da8" />
              </Box>
              <Typography variant="h4" align="center">
                Import your spending history in CSV format.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} display="flex" justifyContent="flex-end">
              <Box flexGrow="1">
                <BankListPickerExample />
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={3} sx={{mt: 10}} display="flex" alignItems="center" wrap="wrap-reverse">
            <Grid item xs={12} md={6} display="flex" justifyContent="flex-start">
              <Box flexGrow="1">
                <RulesList />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{mb: 2}}
                display="flex"
                justifyContent="center"
              >
                <NumberCircle number="2" color="#6501de" />
              </Box>

              <Typography variant="h4" align="center">
                Create rules to quickly sort your transactions.
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={3} sx={{mt: 10, pb: 3}} display="flex" alignItems="center">
            <Grid item xs={12} md={12}>
              <Box
                sx={{mb: 2}}
                display="flex"
                justifyContent="center"
              >
                <NumberCircle number="3" color="#ff5770" />
              </Box>

              <Typography variant="h4" align="center" sx={{mb: 2}}>
                Explore your data and discover new insights.
              </Typography>
            </Grid>
          </Grid>

          <ExampleChart />

          <Stack
            sx={{ pt: 4 }}
            direction="row"
            spacing={2}
            justifyContent="center"
          >
            <Button
              onClick={() => currentUserProfile ? navigate('/app/budget/help') : navigate('/app/signin')}
              variant="outlined"
            >
              Explore more visualisations <ArrowForwardIosIcon style={{fontSize: '0.75rem'}} sx={{ml: 1}} />
            </Button>
          </Stack>
        </Container>

        <Box className='animated-gradient' sx={{py: 10, my: 10}}>
          <Typography
            component="h3"
            variant="h3"
            align="center"
            color="text.contrastText"
            gutterBottom
            style={{
              fontWeight: 800
            }}
            sx={{
              mb: 5
            }}
          >
            Pricing
          </Typography>

          <Typography
            variant="h5"
            align="center"
            color="text.contrastText"
            style={{opacity: 0.9}}
            sx={{
              mb: 5
            }}
          >
            Two affordable payment options.
          </Typography>

          <Container maxWidth="md">
            <Grid container spacing={2} display="flex" alignItems="center">
              <Grid item xs={0} md={3}/>

              <Grid item xs={12} md={3}>
                <SubscriptionItem
                  head="Monthly"
                  description="$5 per month"
                  tagline="Cancel any time"
                  onClick={() => (currentUserProfile ? navigate('/app/account/subscription') : navigate('/app/signup'))}
                />
              </Grid>

              <Grid item xs={12} md={3} display="flex" justifyContent="flex-end">
                <SubscriptionItem
                  head="Yearly"
                  description="$50 per year"
                  tagline="Two months free!"
                  onClick={() => (currentUserProfile ? navigate('/app/account/subscription') : navigate('/app/signup'))}
                />
              </Grid>
            </Grid>

            <Box display="flex" justifyContent="center" sx={{pt: 5}}>
              <Button
                style={{color: 'white', borderColor: 'white'}}
                onClick={() => (currentUserProfile ? navigate('/app/budget/analysis') : navigate('/app/signup'))}
                variant="outlined"
              >
                Or try for free now <ArrowForwardIosIcon style={{fontSize: '0.75rem'}} sx={{ml: 1}} />
              </Button>
            </Box>
          </Container>
        </Box>

        <Container maxWidth="sm" sx={{mb: 10}}>
          <List sx={{ width: '100%' }}>
            <ListItem sx={{mb: 2}}>
              <ListItemAvatar>
                <Avatar>
                  <LanguageIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Works with any bank statement that can be exported to CSV."/>
            </ListItem>

            <ListItem sx={{mb: 2}}>
              <ListItemAvatar>
                <Avatar>
                  <SecurityIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Your transaction history never leaves your browser."/>
            </ListItem>

            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <SaveIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Categories and mappings are permanently saved to your account."/>
            </ListItem>
          </List>

          <Typography sx={{mt: 8}} color="text.secondary" textAlign="center">
            Budget Analyser is not financial advice.
            Due to variations in format, data may be
            transformed during analysis, including the ommission of datapoints.
            The outputs of this tool are not designed to be used
            as legal or financial instruments and should be considered
            illustrative.
          </Typography>
        </Container>



        <Divider />

        <Footer />
      </Box>
    </ThemeProvider>
  )
}