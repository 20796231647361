import * as React from 'react';
import { useDispatch } from 'react-redux'
import { useNavigate, useLocation } from "react-router-dom"
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { ThemeProvider } from '@mui/material/styles'
import Toolbar from '@mui/material/Toolbar'
import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'

import AppBar from '../../components/AppBar'
import Copyright from '../../components/Copyright'

import { addSnackbar } from '../../redux/snackbars'

import completeEmailConfirmation from '../../api/auth/confirmEmail'

import mdTheme from '../../lib/mdTheme'

export default function CompleteConfirmEmail() {
  const dispatch = useDispatch()
  const { search } = useLocation()
  const navigate = useNavigate()

  const [submitting, setSubmitting] = React.useState(true)

  const [errorMessage, setErrorMessage] = React.useState('')
  const [messageSeverity, setMessageSeverity] = React.useState('error')

  const handleCompleteEmailConfirmation = () => {
    setSubmitting(true)
    const params = new URLSearchParams(search)
    const token = params.get('token')
    const email = params.get('email')

    completeEmailConfirmation(email, token)
      .then(() => {
        setSubmitting(false)

        dispatch(addSnackbar({id: 'emailconfirmsuccess', text: 'Email confirmation successful.'}))

        navigate('/app/signin')
      })
      .catch(err => {
        console.error(err)
        setSubmitting(false)
        setMessageSeverity('error')
        setErrorMessage((err.response && err.response.data && err.response.data.error) || err.message)
      })
  }

  React.useEffect(() => {
    handleCompleteEmailConfirmation()
  }, [])

  return (
    <ThemeProvider theme={mdTheme}>
      <AppBar title="Confirm Email"/>

      <Toolbar />

      {errorMessage &&
      <Stack sx={{ width: '100%' }} spacing={2}>
        <Alert severity={messageSeverity}>{errorMessage}</Alert>
      </Stack>}

      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            Confirming email...
          </Typography>

          <Box sx={{ mt: 3 }}>

            {submitting &&
            <Box sx={{ display: 'flex', justifyContent:  'center' }}>
              <CircularProgress />
            </Box>}

          </Box>
        </Box>

        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}
