import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from "react-router-dom"
import Avatar from '@mui/material/Avatar'
import LoadingButton from '@mui/lab/LoadingButton'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import { ThemeProvider } from '@mui/material/styles'
import Toolbar from '@mui/material/Toolbar'
import AppBar from '../../components/AppBar'
import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'

import signup from '../../api/auth/signup'

import { setCurrentUserProfile } from '../../redux/currentUserProfile'
import { addSnackbar } from '../../redux/snackbars'

import Footer from '../../components/Footer'
import FullPageContentContainer from '../../components/FullPageContentContainer'

import emailValid from '../../helpers/validation/emailValid'
import passwordToValidationErrorString from '../../helpers/validation/passwordToValidationErrorString'

import mdTheme from '../../lib/mdTheme'

export default function SignUp() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [submitting, setSubmitting] = React.useState(false)

  const [email, setEmail] = React.useState('')
  const [lastname, setLastname] = React.useState('')
  const [firstname, setFirstname] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [confirmPassword, setConfirmPassword] = React.useState('')

  const [errorMessage, setErrorMessage] = React.useState('')

  const currentUserProfile = useSelector((state) => state.currentUserProfile)
  React.useEffect(() => {
    if (currentUserProfile) return navigate('/app/budget/help')
  })

  const handleSubmit = (event) => {
    event.preventDefault()

    setSubmitting(true)

    signup(email, password, firstname, lastname)
      .then(profile => {
        setSubmitting(false)

        dispatch(setCurrentUserProfile(profile))
        dispatch(addSnackbar({id: 'signupsuccess', text: 'Sign up successful.'}))

        navigate('/app/confirm-email')
      })
      .catch(err => {
        console.error(err)
        setSubmitting(false)
        setErrorMessage((err.response && err.response.data && err.response.data.error) || err.message)
      })
  }

  const passwordValidationErrorMessage = passwordToValidationErrorString(password, confirmPassword)
  const emailValidationErrorMessage = email && !emailValid(email) && 'Email invalid.'
  const submitDisabled = !(email && password && confirmPassword && !passwordValidationErrorMessage && !emailValidationErrorMessage)
  const validationErrorMessage = emailValidationErrorMessage || passwordValidationErrorMessage

  return (
    <ThemeProvider theme={mdTheme}>
      <AppBar title="Sign Up"/>

      <Toolbar />

      {errorMessage &&
      <Stack sx={{ width: '100%' }} spacing={2}>
        <Alert severity="error">{errorMessage}</Alert>
      </Stack>}

      <FullPageContentContainer maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
            <LockOutlinedIcon />
          </Avatar>

          <Typography component="h1" variant="h5">
            Sign Up
          </Typography>

          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }} width="100%">
            <Grid container spacing={2}>
              {/* <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  onChange={e => setFirstname(e.target.value)}
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  onChange={e => setLastname(e.target.value)}
                  autoComplete="family-name"
                />
              </Grid> */}
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  onChange={e => setEmail(e.target.value)}
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  onChange={e => setPassword(e.target.value)}
                  autoComplete="new-password"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="confirmPassword"
                  label="Confirm Password"
                  type="password"
                  id="confirmPassword"
                  onChange={e => setConfirmPassword(e.target.value)}
                  autoComplete="new-password"
                />
              </Grid>
              {/* <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox value="allowExtraEmails" color="primary" />}
                  label="I want to receive inspiration, marketing promotions and updates via email."
                />
              </Grid> */}
            </Grid>

            {validationErrorMessage &&
            <Typography sx={{ mt: 2 }} color="error">
              {validationErrorMessage}
            </Typography>}

            <LoadingButton
              disabled={submitDisabled}
              type="submit"
              loading={submitting}
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </LoadingButton>

            <Grid container justifyContent="center">
              <Grid item>
                <Link href="/app/signin" variant="body2">
                  Sign In
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </FullPageContentContainer>

      <Footer />
    </ThemeProvider>
  );
}
