import bankwest from './bankwest'
import custom from './custom'
import ing from './ing'
import upbank from './upbank'
import commbank from './commbank'
import nab from './nab'
import anz from './anz'
import westpac from './westpac'

export const bankList = [
  {
    id: 'commbank',
    name: 'Commonwealth Bank',
    imageName: 'commbank.jpg'
  },
  {
    id: 'westpac',
    name: 'Westpac',
    imageName: 'westpac.jpg'
  },
  {
    id: 'bankwest',
    name: 'Bankwest',
    imageName: 'bankwest.jpg'
  },
  // {
  //   id: 'nab',
  //   name: 'NAB',
  //   imageName: 'nab.jpg'
  // },
  // {
  //   id: 'anz',
  //   name: 'ANZ',
  //   imageName: 'anz.jpg'
  // },
  {
    id: 'ing',
    name: 'ING',
    imageName: 'ing.jpg'
  },
  {
    id: 'upbank',
    name: 'Up Banking',
    imageName: 'upbank.jpg'
  },
]

export const bankToImportOptions = {
  custom,
  ing,
  upbank,
  commbank,
  nab,
  anz,
  westpac,
  bankwest
}
