import * as React from 'react'
// import Highcharts from 'highcharts'
// import HighchartsReact from 'highcharts-react-official'
import { Bar } from 'react-chartjs-2'
import { Chart, Tooltip, Legend, CategoryScale, LinearScale, BarElement } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'

import Paper from '@mui/material/Paper'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

// import dollarsToDollarString from '../../../../../../helpers/money/dollarsToDollarString'
// import entriesToHighchartsStackedColumns from '../../../../../../helpers/charts/entriesToHighchartsStackedColumns'
import entriesToChartJSStackedColumns from '../../../../../../helpers/charts/entriesToChartJSStackedColumns'
import stackedColumnChartJSOptions from '../../../../../../helpers/charts/stackedColumnChartJSOptions'

// const stackedOptionsChartJS = require('./stackedOptionsChartJS.js')
// const stackedColumnChartJSOptions = require('./stackedColumnChartJSOptions.js')

Chart.register(Tooltip, Legend, ChartDataLabels, CategoryScale, LinearScale, BarElement)

export default function Monthly({
  entries=[]
}) {
  // const chartComponentRef = React.useRef(null)
  // const stackedOptionsHighcharts = entriesToHighchartsStackedColumns(entries)

  const stackedOptionsChartJS = entriesToChartJSStackedColumns(entries)

  if (entries.length === 0) return <Typography>No transactions categorised.</Typography>

  return (
    <Paper elevation={3}>
      {/* <Box sx={{p: 2}}>
        <Typography variant="p">
          Spend per month.
        </Typography>
      </Box>
      <Divider /> */}

      {/* <Box sx={{my: 3, pt:4, pb: 2, pl: 1, pr: 3}}>
        <HighchartsReact
          highcharts={Highcharts}
          options={stackedOptionsHighcharts}
          updateArgs={[true]}
          ref={chartComponentRef}
        />
      </Box> */}
      <Box sx={{p: 2}}>
        <Typography variant="p">
          This stacked column chart shows your expenses month by month, broken into categories.
        </Typography>
      </Box>

      <Divider />

      <Box
        style={{width: '100%', height: '80vh', maxHeight: '600px'}}
        sx={{p: 2, py: 1}}
      >
        <Box style={{height: '100%'}}>
          <Bar
            data={stackedOptionsChartJS}
            options={stackedColumnChartJSOptions}
          />
        </Box>
      </Box>
    </Paper>
  )
}
