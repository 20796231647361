const commbank = {
  dateColumnName: undefined,
  descriptionColumnName: undefined,
  amountColumnName: undefined,

  dateColumnIndex: 0,
  descriptionColumnIndex: 2,
  amountColumnIndex: 1,

  dateFormat: 'DD/MM/YYYY',

  hasHeaderRow: false,
  negativeValuesOnly: true,
}

export default commbank
