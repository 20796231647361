import * as React from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'

import { bankList } from '../../../../helpers/csvFile/presetImportOptions'

export default function PresetImportOptionsPicker() {
  const [value, setValue] = React.useState('')
  return (
    <FormControl fullWidth>
      <InputLabel id="bank">Select Bank</InputLabel>
      <Select
        fullWidth
        labelId="bank"
        value={value}
        label="Select Bank"
        onChange={e => setValue(e.target.value)}
      >
        {bankList && bankList.map(bank => (
          <MenuItem key={bank.id} value={bank.id}>
            <Box display="flex" alignItems="center" sx={{py: 1}}>
              <img alt={`Bank ${bank.name}`} src={`/img/bank-icons/${bank.imageName}`} style={{borderRadius: '50%', height: '1.5rem', marginRight: '0.5rem'}} />
              <Typography>{bank.name}</Typography>
            </Box>
          </MenuItem>
        ))}
        <MenuItem value="custom">
          <Box display="flex" alignItems="center" sx={{py: 1}}>
            <span style={{opacity: 0, height: '1.5rem'}} />
            <Typography>Custom CSV</Typography>
          </Box>
        </MenuItem>
      </Select>
      <Typography sx={{mt: 2}} color="text.secondary" textAlign="center">
        We are not affiliated with the listed banks.
      </Typography>
    </FormControl>
  )
}
