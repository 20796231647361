import * as React from 'react'
import TextField from '@mui/material/TextField'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import InputAdornment from '@mui/material/InputAdornment'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import CheckIcon from '@mui/icons-material/Check'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'

export default function CustomCSVImportOptionsForm({
  value,
  onChange,

  dateColumnFound,
  descriptionColumnFound,
  amountColumnFound,

  dateColumnIndexValid,
  descriptionColumnIndexValid,
  amountColumnIndexValid,

  dateFormatValid,
}) {
  const {
    dateColumnName,
    descriptionColumnName,
    amountColumnName,

    dateColumnIndex,
    descriptionColumnIndex,
    amountColumnIndex,

    dateFormat,

    negativeValuesOnly,
    hasHeaderRow,
  } = (value || {})

  // all these functions because we're editing a big object here

  const setDateColumnName = dateColumnName => onChange(Object.assign({}, value, {dateColumnName}))
  const setDescriptionColumnName = descriptionColumnName => onChange(Object.assign({}, value, {descriptionColumnName}))
  const setAmountColumnName = amountColumnName => onChange(Object.assign({}, value, {amountColumnName}))

  const setDateColumnIndex = dateColumnIndex => onChange(Object.assign({}, value, {dateColumnIndex}))
  const setDescriptionColumnIndex = descriptionColumnIndex => onChange(Object.assign({}, value, {descriptionColumnIndex}))
  const setAmountColumnIndex = amountColumnIndex => onChange(Object.assign({}, value, {amountColumnIndex}))

  const setDateFormat = dateFormat => onChange(Object.assign({}, value, {dateFormat}))

  const setNegativeValuesOnly = negativeValuesOnly => onChange(Object.assign({}, value, {negativeValuesOnly}))
  const setHasHeaderRow = hasHeaderRow => onChange(Object.assign({}, value, {hasHeaderRow}))

  return (
    <Box>
      <Grid container spacing={2}>

        <Grid item xs={12} sx={{mt: -1}}>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox
              checked={hasHeaderRow || false}
              onChange={e => setHasHeaderRow(e.target.checked)} />}
              label="Has header row"
            />
          </FormGroup>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{mt: -1}}>
        {hasHeaderRow ?
          <Grid item xs={6}>
            <TextField
              name="dateColumnName"
              fullWidth
              id="dateColumnName"
              label="Date Column Name"
              value={dateColumnName || ''}
              onChange={e => setDateColumnName(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {dateColumnFound ?
                    <CheckIcon color="primary" /> :
                    <QuestionMarkIcon color="secondary" />}
                  </InputAdornment>
                )
              }}
            />
          </Grid>
        :
          <Grid item xs={6}>
            <TextField
              name="dateColumnIndex"
              fullWidth
              id="dateColumnIndex"
              label="Date Column Index"
              type="number"
              value={dateColumnIndex || 0}
              onChange={e => setDateColumnIndex(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {dateColumnIndexValid ?
                    <CheckIcon color="primary" /> :
                    <QuestionMarkIcon color="secondary" />}
                  </InputAdornment>
                )
              }}
            />
          </Grid>
        }
        <Grid item xs={6}>
          <TextField
            name="dateFormat"
            fullWidth
            id="dateFormat"
            disabled={!dateColumnFound}
            label="Date Format"
            value={dateFormat || ''}
            onChange={e => setDateFormat(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {dateFormatValid ?
                  <CheckIcon color="primary" /> :
                  <QuestionMarkIcon color="secondary" />}
                </InputAdornment>
              )
            }}
          />
        </Grid>

        <Grid item xs={6}>
          {hasHeaderRow ?
            <TextField
              name="descriptionColumnName"
              fullWidth
              id="descriptionColumnName"
              label="Description Column Name"
              value={descriptionColumnName || ''}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {descriptionColumnFound ?
                    <CheckIcon color="primary" /> :
                    <QuestionMarkIcon color="secondary" />}
                  </InputAdornment>
                )
              }}
              onChange={e => setDescriptionColumnName(e.target.value)}
            />
          :
            <TextField
              name="descriptionColumnIndex"
              fullWidth
              id="descriptionColumnIndex"
              label="Description Column Index"
              value={descriptionColumnIndex || 0}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {descriptionColumnIndexValid ?
                    <CheckIcon color="primary" /> :
                    <QuestionMarkIcon color="secondary" />}
                  </InputAdornment>
                )
              }}
              onChange={e => setDescriptionColumnIndex(e.target.value)}
            />
          }
        </Grid>
        <Grid item xs={6}>
          {hasHeaderRow ?
            <TextField
              name="amountColumnName"
              fullWidth
              id="amountColumnName"
              label="Amount Column Name"
              value={amountColumnName || ''}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {amountColumnFound ?
                    <CheckIcon color="primary" /> :
                    <QuestionMarkIcon color="secondary" />}
                  </InputAdornment>
                )
              }}
              onChange={e => setAmountColumnName(e.target.value)}
            />
          :
            <TextField
              name="amountColumnIndex"
              fullWidth
              id="amountColumnIndex"
              label="Amount Column Index"
              value={amountColumnIndex || 0}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {amountColumnIndexValid ?
                    <CheckIcon color="primary" /> :
                    <QuestionMarkIcon color="secondary" />}
                  </InputAdornment>
                )
              }}
              onChange={e => setAmountColumnIndex(e.target.value)}
            />
          }
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{mt: -1}}>
        <Grid item xs={12}>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox
              checked={negativeValuesOnly || false}
              onChange={e => setNegativeValuesOnly(e.target.checked)} />}
              label="Negative debit values only"
            />
          </FormGroup>
        </Grid>
      </Grid>
    </Box>
  )
}
