import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import CircularProgress from '@mui/material/CircularProgress'
import Alert from '@mui/material/Alert'
import FilterList from '@mui/icons-material/FilterList'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import CircleIcon from '@mui/icons-material/Circle'

import CreateMapping from './components/CreateMapping'

import Copyright from '../../../../components/Copyright'
import HeadingContainer from '../../../../components/HeadingContainer'
import FilterContainer from '../../../../components/FilterContainer'
import searchCategories from '../../../../api/categories/search'

import searchMappings from '../../../../api/mappings/search'

import { addSnackbar } from '../../../../redux/snackbars'
import EditMappingDialog from './components/EditMappingDialog'
import centsToDollarString from '../../../../helpers/money/centsToDollarString'

// const PER_PAGE = 10

export default function Mappings() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [mappingsLoading, setMappingsLoading] = React.useState(true)
  const [mappings, setMappings] = React.useState(null)
  const [errorMessage, setErrorMessage] = React.useState('')
  const [categoriesLoading, setCategoriesLoading] = React.useState(true)
  const [categories, setCategories] = React.useState(null)
  const [categoryUuid, setCategoryUuid] = React.useState('')
  const [editMappingsDialogOpen, setEditMappingsDialogOpen] = React.useState(false)
  const [mappingToEdit, setMappingToEdit] = React.useState(null)

  // const [page, setPage] = React.useState(1)
  // const [totalPages, setTotalPages] = React.useState(0)

  const handleSearchMappings = () => {
    setMappingsLoading(true)
    searchMappings({
      categoryUuid
      // page,
      // perPage: PER_PAGE
    }).then((mappings) => {
      setMappings(mappings)
      // setTotalPages(totalPages)
      setMappingsLoading(false)
    }).catch(err => {
      console.error(err)
      setMappingsLoading(false)
      setErrorMessage((err.response && err.response.data && err.response.data.error) || err.message)
    })
  }

  React.useEffect(() => {
    handleSearchMappings()
  }, [categoryUuid])

  React.useEffect(() => {
    searchCategories().then((categories) => {
      setCategoriesLoading(false)
      setCategories(categories)
    }).catch(err => {
      console.error(err)
      setCategoriesLoading(false)
      dispatch(addSnackbar({id: 'categorysearchfail', text: 'Failed to get categories.'}))
    })
  }, [])

  return (
    <div>
      {errorMessage &&
      <Stack sx={{ width: '100%' }} spacing={2}>
        <Alert severity="error">{errorMessage}</Alert>
      </Stack>}

      <HeadingContainer>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
        >
          Rules
        </Typography>
      </HeadingContainer>

      <Divider />

      <EditMappingDialog
        open={editMappingsDialogOpen}
        handleClose={() => setEditMappingsDialogOpen(false)}
        onCreate={handleSearchMappings}
        categories={categories}
        mapping={mappingToEdit}
        categoriesLoading={categoriesLoading}
      />

      <Container maxWidth='md' sx={{mb: 3, pt: 3}}>
        <CreateMapping
          onCreate={handleSearchMappings}
          categories={categories}
          categoriesLoading={categoriesLoading}
        />
      </Container>

      {mappingsLoading &&
      <Box sx={{ display: 'flex', justifyContent:  'center' }}>
        <CircularProgress />
      </Box>}


      {!mappingsLoading &&
      <Container maxWidth='md'>
        {/* {totalPages > 1 &&
        <Pagination
          sx={{mb: 3}}
          count={totalPages}
          page={page}
          onChange={(_, v) => setPage(v)}
        />} */}

        {mappings && mappings.length > 0 &&
        <Paper elevation={3} style={{overflowX: 'scroll'}}>
          <FilterContainer>
            <FilterList sx={{ fontSize: 22 }} />
            <b style={{fontSize: '13px', paddingLeft: '0.15rem', paddingRight: '1rem'}}>Filter</b>

            {categoriesLoading && <CircularProgress />}
            {!categoriesLoading &&
            <FormControl fullWidth>
              <InputLabel id="category">Category</InputLabel>
              <Select
                fullWidth
                labelId="category"
                value={categoryUuid}
                label="Category"
                onChange={e => setCategoryUuid(e.target.value)}
              >
                {categories && categories.map(c => (
                  <MenuItem key={c.uuid} value={c.uuid}>
                    <Box display="flex" alignItems="center">
                      {c.color &&
                      <CircleIcon style={{color: c.color}} sx={{mr: 2}} />}
                      <span>{c.name}</span>
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>}
          </FilterContainer>


          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left"><b>Category</b></TableCell>
                <TableCell align="left"><b>Match Description</b></TableCell>
                <TableCell align="left"><b>Match Amount</b></TableCell>
                <TableCell align="right"></TableCell>
                {/* <TableCell align="right" /> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {mappings && mappings.map((mapping) => (
                <TableRow
                  key={mapping.uuid}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="left">
                    <Box display="flex" alignItems="center">
                      {mapping.category && mapping.category.color &&
                      <CircleIcon style={{color: mapping.category.color}} sx={{mr: 2}} />}
                      <span>{mapping.category.name}</span>
                    </Box>
                  </TableCell>

                  <TableCell align="left">{mapping.descriptionGlob || <i>None</i>}</TableCell>
                  <TableCell align="left">{mapping.matchAmount ? centsToDollarString(mapping.matchAmount) : <i>None</i>}</TableCell>
                  <TableCell align="right">
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={() => {setMappingToEdit(mapping) ; setEditMappingsDialogOpen(true)}}
                    >
                      Edit
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>}
      </Container>}

      <Copyright sx={{ pt: 4 }} />
    </div>
  );
}
