import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { BrowserRouter, Routes, Route, } from 'react-router-dom'

import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

import GoogleAnalyticsReporter from './hoc/GoogleAnalyticsReporter'

import FetchUserProfile from './hoc/FetchUserProfile'

import Home from './pages/Home'
import Privacy from './pages/Privacy'
import TermsOfUse from './pages/TermsOfUse'
import Account from './pages/Account'
import Profile from './pages/Account/pages/Profile'
import AccountSubscriptions from './pages/Account/pages/AccountSubscriptions'
import ChangePassword from './pages/Account/pages/ChangePassword'

import SignIn from './pages/SignIn'
import SignUp from './pages/SignUp'
import Contact from './pages/Contact'
import ForgotPassword from './pages/ForgotPassword'
import CompletePasswordReset from './pages/CompletePasswordReset'
import ConfirmEmail from './pages/ConfirmEmail'
import CompleteConfirmEmail from './pages/CompleteConfirmEmail'

import Budget from './pages/Budget'
import Analysis from './pages/Budget/pages/Analysis'
import Mappings from './pages/Budget/pages/Mappings'
import Categories from './pages/Budget/pages/Categories'
import Help from './pages/Budget/pages/Help'

import AdminDashboard from './pages/AdminDashboard'

import AdminUserManagement from './pages/AdminDashboard/pages/AdminUserManagement'
import AdminEditUser from './pages/AdminDashboard/pages/AdminEditUser'


const Fallback = props => {
  const navigate = useNavigate();
  useEffect(() => {
    // TODO: If not auth, go to /signin
    navigate('/app')
  })
  return (
    <main style={{ padding: "1rem" }}>
      <Box sx={{ display: 'flex', justifyContent:  'center' }}>
        <CircularProgress />
      </Box>
    </main>
  )
}

export default function Router() {
  return (
    <BrowserRouter>
      <FetchUserProfile>
        <GoogleAnalyticsReporter>
          <Routes>
            <Route path="app" element={<Home />} />
            <Route path="app/signin" element={<SignIn />} />
            <Route path="app/signup" element={<SignUp />} />
            <Route path="app/contact" element={<Contact />} />
            <Route path="app/privacy" element={<Privacy />} />
            <Route path="app/terms" element={<TermsOfUse />} />
            <Route path="app/forgot-password" element={<ForgotPassword />} />
            <Route path="app/complete-password-reset" element={<CompletePasswordReset />} />
            <Route path="app/confirm-email" element={<ConfirmEmail />} />
            <Route path="app/complete-confirm-email" element={<CompleteConfirmEmail />} />

            <Route path="app/account/*" element={<Account />}>
              <Route path="profile" element={<Profile />} />
              <Route path="change-password" element={<ChangePassword />} />
              <Route path="subscription" element={<AccountSubscriptions />} />
              <Route path="subscription/:status" element={<AccountSubscriptions />} />

              <Route path="*" element={<main style={{ padding: "1rem" }}><p>Please select a page.</p></main>} />
            </Route>

            <Route path="app/budget/*" element={<Budget />}>
              <Route path="analysis" element={<Analysis />} />
              <Route path="mappings" element={<Mappings />} />
              <Route path="categories" element={<Categories />} />
              <Route path="help" element={<Help />} />

              <Route path="*" element={<main style={{ padding: "1rem" }}><p>Please select a page.</p></main>} />
            </Route>

            <Route path="app/admin/*" element={<AdminDashboard />}>
              <Route path="users" element={<AdminUserManagement />} />
              <Route path="users/:userUuid" element={<AdminEditUser />} />

              <Route path="*" element={<main style={{ padding: "1rem" }}><p>Please select a page.</p></main>} />
            </Route>

            <Route path="*" element={<Fallback />} />
          </Routes>
        </GoogleAnalyticsReporter>
      </FetchUserProfile>
    </BrowserRouter>
  )
}
