/* eslint-disable no-template-curly-in-string */
import * as React from 'react'

// import Highcharts from 'highcharts'
// import HighchartsReact from 'highcharts-react-official'
import { Pie } from 'react-chartjs-2'
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js'

import Box from '@mui/material/Box'

import { categories } from '../exampleData'
import pieChartJSOptions from '../../../../helpers/charts/pieChartJSOptions'

Chart.register(ArcElement, Tooltip, Legend)

// const chartDataHighcharts = {
//   chart: {
//     plotBackgroundColor: null,
//     plotBorderWidth: null,
//     plotShadow: false,
//     type: "pie"
//   },
//   title: {
//     text: ""
//   },
//   credits: {
//     enabled: false
//   },
//   tooltip: {
//     headerFormat: "<span/>",
//     pointFormat: "<b>{point.name}:</b> ${point.y}"
//   },
//   plotOptions: {
//     pie: {
//       allowPointSelect: true,
//       cursor: "pointer",
//       dataLabels: {
//         enabled: true,
//         format: "<b>{point.name}</b>: ${point.y:.2f}"
//       }
//     }
//   },
//   series: [
//     {
//       name: "Spending",
//       colorByPoint: true,
//       data: categories
//     }
//   ]
// }


const pieDataChartJS = {
  labels: categories.map(c => (c.name)),
  datasets: [{
    label: 'Expenses',
    data: categories.map(c => (c.y)),
    backgroundColor: categories.map(c => (c.color)),
    hoverOffset: 4,
    borderWidth: 1
  }]
}

export default function ExampleChart() {
  return (
    <Box
      style={{width: '100%', height: '50vh', maxHeight: '350px'}}
      sx={{px: 2}}
    >
      <Pie
        data={pieDataChartJS}
        options={pieChartJSOptions(pieDataChartJS)}
      />
    </Box>
  )
  // return (
  //   <HighchartsReact
  //     highcharts={Highcharts}
  //     options={chartDataHighcharts}
  //     updateArgs={[true]}
  //   />
  // )
}
