import * as React from 'react'
import { styled, ThemeProvider } from '@mui/material/styles'
import MuiAppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import AppBarItems from './AppBarItems'

import mdTheme from '../../lib/mdTheme'

const drawerWidth = 240

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

export default function AppBarComponent(props) {
  const {
    title,
    invert
  } = props

  const appBarStyle = invert ? {
    backgroundColor: 'rgba(0,0,0,0)',
    boxShadow: 'none'
  } : {}

  return (
    <ThemeProvider theme={mdTheme}>
      <AppBar
        position="absolute"
        style={appBarStyle}
      >
        <Toolbar
          sx={{
            pr: '24px', // keep right padding when drawer closed
          }}
        >
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            {title}
          </Typography>

          <AppBarItems />
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
}
