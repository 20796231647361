import * as React from 'react'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { ThemeProvider } from '@mui/material/styles'

import AppBar from '../../components/AppBar'
import Footer from '../../components/Footer'
import FullPageContentContainer from '../../components/FullPageContentContainer'

import mdTheme from '../../lib/mdTheme'

export default function TermsOfUse() {
  return (
    <ThemeProvider theme={mdTheme}>
      <AppBar title="Terms of Use"/>

      <Toolbar />

      <FullPageContentContainer>
        <Typography
          component="h1"
          variant="h2"
          align="left"
          color="text.primary"
          gutterBottom
        >
          Terms of Use
        </Typography>

        <Typography variant="p">
          By using this website for any purpose you accept the following Terms of Use.
          Violation of the Terms of Use can result in your account being terminated or your
          access being blocked. You are not entitled to a refund of purchases made on the website
          if your account is terminated for violating the Terms of Use.
          <br/>
          <br/>
          Violations of the terms of use include:
          <ul>
            <li>Taking any actions that harm the website's performance or security.</li>
            <li>Losing control of your account and account sharing.</li>
            <li>Unauthorized use of the website.</li>
            <li>Stealing or scraping data from the website.</li>
            <li>Cloning, copying, or replicating the website.</li>
            <li>Transferring malicious files, code, or commands to the website.</li>
            <li>Manipulating elements of the website (using software or otherwise) for any purpose including attempting to bypass limits or escalation of privilege.</li>
            <li>Use of the website for any illegal activities, including uploading illegal material.</li>
          </ul>
          We do not take responsibility for the performance or reliability of any application. We are
          not liable for any costs that result from your use of this service. Do not build software
          integrations on top of this service.
          <br/>
          <br/>
          Services offered by this site are liable to change. Functionality, including paid features, may be modified, added or removed.
          <br/>
          <br/>
        </Typography>

      </FullPageContentContainer>

      <Footer />
    </ThemeProvider>
  );
}