import dollarsToDollarString from '../money/dollarsToDollarString'

const pieChartJSOptions = data => ({
  responsive: true,
  maintainAspectRatio: false,
  layout: {
    padding: 40
  },
  plugins: {
    // legendMargin: true,
    legend: {
      position: 'left',
    },
    datalabels: {
      display: 'auto',
      anchor: 'end',
      align: 'end',
      font: {
        weight: 800,
        size: 14
      },
      formatter: function(value, context) {
        return `${data.labels[context.dataIndex]}\n${dollarsToDollarString(context.chart.data.datasets[0].data[context.dataIndex])}`
      },
      color: function(context) {
        return context.dataset.backgroundColor[context.dataIndex]
      }
    },
    tooltip: {
      callbacks: {
        label: tooltipItem => (tooltipItem.label),
        title: tooltipItem => (dollarsToDollarString(tooltipItem[0].raw))
      }
    }
  },
})

export default pieChartJSOptions
