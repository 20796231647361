import Container from '@mui/material/Container'

export default function FullPageContentContainer({
  maxWidth,
  children,
  sx={}
}) {
  return (
    <Container
      maxWidth={maxWidth || "md"}
      sx={{minHeight: "60vh", pt: 3, ...sx}}
    >
      {children}
    </Container>
  );
}
