const ing = {
  dateColumnName: 'date',
  descriptionColumnName: 'description',
  amountColumnName: 'debit',

  dateColumnIndex: undefined,
  descriptionColumnIndex: undefined,
  amountColumnIndex: undefined,

  dateFormat: 'DD/MM/YYYY',

  hasHeaderRow: true,
  negativeValuesOnly: false
}

export default ing
