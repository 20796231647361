import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import LoadingButton from '@mui/lab/LoadingButton'
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Grid'

import { redirectToCheckout, redirectToPortal } from '../../../../lib/stripe'
import HeadingContainer from '../../../../components/HeadingContainer'
import SubscriptionItem from '../../../../components/SubscriptionItem'
import Copyright from '../../../../components/Copyright'

import { addSnackbar } from '../../../../redux/snackbars'

export default function AccountSubscriptions() {
  const dispatch = useDispatch()
  const currentUserProfile = useSelector(state => state.currentUserProfile)
  const { status } = useParams()

  const [submitting, setSubmitting] = React.useState(false)
  const [infoMessage, setInfoMessage] = React.useState('')

  const handleCheckoutClick = (sku) => {
    setSubmitting(true)
    redirectToCheckout({sku}).then(() => {
      // setSubmitting(false)
    }).catch(err => {
      setSubmitting(false)
      console.error('Error creating checkout session', err)
      const text = (err.response && err.response.data && err.response.data.error) || 'Failed to create checkout session.'
      dispatch(addSnackbar({id: 'checkoutsessionfailed', text}))
    })
  }

  const handlePortalClick = () => {
    setSubmitting(true)
    redirectToPortal().then(() => {
      // setSubmitting(false)
    }).catch(err => {
      setSubmitting(false)
      console.error('Error creating checkout session', err)
      const text = (err.response && err.response.data && err.response.data.error) || 'Failed to create portal session.'
      dispatch(addSnackbar({id: 'checkoutsessionfailed', text}))
    })
  }

  const hasActiveStripeSubscription = currentUserProfile && currentUserProfile.hasActiveStripeSubscription

  React.useEffect(() => {
    if (status === 'success') setInfoMessage('Thanks for subscribing!')
  }, [status])

  return (
    <div>
      <HeadingContainer>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
        >
          Subscription
        </Typography>
      </HeadingContainer>
      <Divider />

      {infoMessage &&
      <Stack sx={{ width: '100%' }} spacing={2}>
        <Alert severity="info">{infoMessage}</Alert>
      </Stack>}

      {hasActiveStripeSubscription &&
      <Container maxWidth="sm">
        <Typography
          component="h1"
          variant="h6"
          color="text.secondary"
          textAlign="center"
          sx={{ flexGrow: 1, my: 4 }}
        >
          Manage your susbcription by launching the customer portal.
        </Typography>

        <LoadingButton
          color="primary"
          variant="contained"
          sx={{mb: 2}}
          fullWidth
          loading={submitting}
          onClick={handlePortalClick}
        >
          Launch Customer Portal
        </LoadingButton>
      </Container>}


      {!hasActiveStripeSubscription && submitting &&
      <Box sx={{ display: 'flex', justifyContent:  'center' }}>
        <CircularProgress sx={{my: 2}} />
      </Box>}

      {!hasActiveStripeSubscription && !submitting &&
      <Container maxWidth="md">

        <Typography
          component="h1"
          variant="h6"
          color="text.secondary"
          textAlign="center"
          sx={{ flexGrow: 1, my: 4 }}
        >
          To import more than 10 transactions, please subscribe by selecting a plan below.
        </Typography>

        <Grid container spacing={2} display="flex" alignItems="center" sx={{my: 4}}>
          <Grid item xs={0} md={3}/>

          <Grid item xs={12} md={3}>
            <SubscriptionItem
              head="Monthly"
              description="$5 per month"
              tagline="Cancel any time"
              onClick={() => handleCheckoutClick('monthly')}
            />
          </Grid>

          <Grid item xs={12} md={3} display="flex" justifyContent="flex-end">
            <SubscriptionItem
              head="Yearly"
              description="$50 per year"
              tagline="Two months free!"
              onClick={() => handleCheckoutClick('yearly')}
            />
          </Grid>
        </Grid>
      </Container>}

      <Container maxWidth="sm">
        <Box display="flex" alignItems="center" justifyContent="center">
          <img
            alt="Powered by Stripe"
            src="/img/stripeblurple.svg"
            style={{maxHeight: '2rem', marginBottom: '1rem'}}
          />
        </Box>
      </Container>

      <Copyright sx={{ pt: 4 }} />
    </div>
  );
}
