import * as React from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import Typography from '@mui/material/Typography'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import outmatch from 'outmatch'

export default function CSVFilePreviewTrainer() {
  const [descriptionGlob, setDescriptionGlob] = React.useState('KFC*')
  const csvFileRows = [
    ['Date', 'Description', 'Amount'],
    ['02/01/2022', 'KFC SUBIACO - Receipt 055010', '$5.40'],
    ['07/01/2022', 'KFC INNALOO - Receipt 142510', '$9.55'],
  ]

  const headerRows = csvFileRows.slice(0, 1)
  const contentRows = csvFileRows.slice(1, 5)

  const dMatcher = outmatch(descriptionGlob, false)

  return (
    <Box>

      <TextField
        name="descriptionGlob"
        fullWidth
        id="descriptionGlob"
        label="Match Description"
        value={descriptionGlob}
        onChange={e => setDescriptionGlob(e.target.value)}
      />

      <Paper elevation={3} sx={{mt: 2}} style={{overflowX: 'scroll'}}>

        <Table size='small'>
          <TableBody>

            {headerRows.map((row, i) => (
              <TableRow
                // TODO: stringify bad for performance. I guess it's okay just for preview.
                key={`${JSON.stringify(row)}${i}`}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                {row.map((c, j) => (
                  <TableCell key={`${c}${j}`} align="left">
                    <Typography
                      variant="p"
                      color={"inherit"}
                      style={{fontWeight: 600}}
                    >
                      {c}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            ))}

            {contentRows.map((row) => {
              const match = dMatcher(row[1])
              return (
                <TableRow
                  key={JSON.stringify(row)}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  {row.map((c, i) => (
                    <TableCell key={`${c}${i}`} align="left">
                      <Typography
                        variant="p"
                        color={match ? "primary" : "inherit"}
                      >
                        {c}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </Paper>
    </Box>
  )
}
