import { useNavigate } from 'react-router'

import Link from '@mui/material/Link'

export default function RouterLink({
  children,
  underline,
  color,
  href
}) {
  const navigate = useNavigate()

  return (
    <Link
      onClick={e => {e.preventDefault(); href && navigate(href)}}
      underline={underline}
      color={color}
      href={href}
    >
      {children}
    </Link>
  );
}
