import * as React from 'react'
// import Highcharts from 'highcharts'
// import HighchartsReact from 'highcharts-react-official'
import { Pie } from 'react-chartjs-2'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js'
import seriesDataFromMatchedEntries from '../../../../../../helpers/charts/seriesDataFromMatchedEntries'
import pieChartJSOptions from '../../../../../../helpers/charts/pieChartJSOptions'
import seriesDataToChartJSPieChart from '../../../../../../helpers/charts/seriesDataToChartJSPieChart'
// import seriesDataToHighchartsPieChart from '../../../../../../helpers/charts/seriesDataToHighchartsPieChart'

Chart.register(ArcElement, Tooltip, Legend, ChartDataLabels)
// Chart.register({
//   id: 'legendMargin',
//   beforeInit: (chart, legend, options) => {
//     const fitValue = chart.legend.fit
//     chart.legend.fit = function() {
//       fitValue.bind(chart.legend)()
//       return this.width
//       // return this.width += 40
//     }
//   }
// })

export default function PieChart({
  matchedEntries,
  filteredMatchedEntries,
}) {
  if (!filteredMatchedEntries) return null

  if (filteredMatchedEntries.length === 0) return <Typography>No transactions categorised.</Typography>

  const pieDataChartJS = filteredMatchedEntries && seriesDataToChartJSPieChart(seriesDataFromMatchedEntries(filteredMatchedEntries))
  // const pieOptionsHighchart = filteredMatchedEntries && seriesDataToHighchartsPieChart(seriesDataFromMatchedEntries(filteredMatchedEntries))

  if (pieDataChartJS && matchedEntries && matchedEntries.length > 0) return (
    <Paper elevation={3} sx={{pb: 2}}>
      <Box sx={{p: 2}}>
        <Typography variant="p">
          Total spend per category over entire date range.
        </Typography>
      </Box>
      <Divider />
      <Box
        style={{width: '100%', height: '80vh', maxHeight: '400px'}}
        sx={{my: 2, pr: 2}}
      >
        {/* <HighchartsReact
          highcharts={Highcharts}
          options={pieOptionsHighchart}
          ref={chartComponentRef}
          updateArgs={[true]}
        /> */}
        <Pie data={pieDataChartJS} options={pieChartJSOptions(pieDataChartJS)} />
      </Box>
    </Paper>
  )

  return null
}
