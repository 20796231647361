const CATEGORY_COLOR_1 = '#6501de'
const CATEGORY_COLOR_2 = '#9e16c3'
const CATEGORY_COLOR_3 = '#c42da8'
const CATEGORY_COLOR_4 = '#e4428d'
const CATEGORY_COLOR_5 = '#ff5770'
// const CATEGORY_COLOR_1 = '#478E75'
// const CATEGORY_COLOR_2 = '#0E553C'
// const CATEGORY_COLOR_3 = '#FFD3AA'
// const CATEGORY_COLOR_4 = '#D49E6A'
// const CATEGORY_COLOR_5 = '#E498AF'

export const categories = [
  { y: 400, color: CATEGORY_COLOR_1, name: 'Rent' },
  { y: 200, color: CATEGORY_COLOR_2, name: 'Groceries' },
  { y: 90, color: CATEGORY_COLOR_3, name: 'Health' },
  { y: 50, color: CATEGORY_COLOR_4, name: 'Beauty' },
  { y: 40, color: CATEGORY_COLOR_5, name: 'Gifts' },
]

export const mappings = [
  { amount: 400, descriptionGlob: '*Landlord*', category: { color: CATEGORY_COLOR_1, name: 'Rent' }},
  { amount: 200, descriptionGlob: 'Coles*', category: { color: CATEGORY_COLOR_2, name: 'Groceries' }},
  { amount: 195, descriptionGlob: 'Gucci*', category: { color: CATEGORY_COLOR_3, name: 'Gifts' }},
  { amount: 59, descriptionGlob: '*MECCA*', category: { color: CATEGORY_COLOR_4, name: 'Beauty' }},
  { amount: 40, descriptionGlob: "*Pharmacy*", category: { color: CATEGORY_COLOR_5, name: 'Health' }},
]
