const bankwest = {
  dateColumnName: 'Transaction Date',
  descriptionColumnName: 'Narration',
  amountColumnName: 'Debit',

  dateColumnIndex: undefined,
  descriptionColumnIndex: undefined,
  amountColumnIndex: undefined,

  dateFormat: 'DD/MM/YYYY',

  hasHeaderRow: true,
  negativeValuesOnly: false
}

export default bankwest
