/* eslint-disable no-template-curly-in-string */

export default function entriesToHighchartsStackedColumns(entries) {
  const stackedOptions = {
    labels: [],
    datasets: []
  }

  const allCategories = []
  let allMMYY = []

  const seriesDictionary = {
    spending: {
      mmyy: 0
    }
  }

  const colorMappings = {}

  entries.forEach(entry => {
    const mmyy = entry.castedDate.format('YYYY/MM')
    const category = entry.category.name
    colorMappings[category] = entry.category.color

    if (category === 'Ignore') return

    if (!seriesDictionary[category])
      seriesDictionary[category] = {}

    if (allCategories.indexOf(category) === -1)
      allCategories.push(category)

    if (!seriesDictionary[category][mmyy])
      seriesDictionary[category][mmyy] = 0

    if (allMMYY.indexOf(mmyy) === -1)
      allMMYY.push(mmyy)

    seriesDictionary[category][mmyy] += Math.abs(Number(entry.debit))
  })

  allMMYY = allMMYY.sort((a, b) => a.localeCompare(b))

  allCategories.forEach(category => {
    stackedOptions.datasets.push({
      label: category,
      backgroundColor: colorMappings[category],
      data: allMMYY.map(mmyy => (seriesDictionary[category][mmyy] || 0))
    })
  })

  stackedOptions.labels = allMMYY

  return stackedOptions
}
