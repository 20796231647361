export default function seriesDataFromMatchedEntries(entries) {
  const debitsDataDict = {}

  for (const entry of entries) {
    const y = entry.debit

    // deconstruct point
    let categoryUuid, name, color
    if (entry.category && entry.category.uuid) {
      categoryUuid = entry.category.uuid
      name = entry.category.name
      color = entry.category.color
    }

    // ensure datastructure
    if (!debitsDataDict[categoryUuid])
      debitsDataDict[categoryUuid] = { y: 0, categoryUuid, name, color }

    debitsDataDict[categoryUuid].y += Math.abs(Number(y))
  }

  return Object.values(debitsDataDict)
}
