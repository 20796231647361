import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LoadingButton from '@mui/lab/LoadingButton'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import CircularProgress from '@mui/material/CircularProgress'
import CircleIcon from '@mui/icons-material/Circle'

import createMapping from '../../../../../../api/mappings/create'
import { addSnackbar } from '../../../../../../redux/snackbars'

export default function CreateMapping({
  onCreate,
  categories,
  categoriesLoading
}) {
  const dispatch = useDispatch()

  const [submitting, setSubmitting] = React.useState(false)

  const [matchAmount, setMatchAmount] = React.useState('')
  const [categoryUuid, setCategoryUuid] = React.useState('')
  const [descriptionGlob, setDescriptionGlob] = React.useState('')

  const handleSubmit = (event) => {
    event.preventDefault()

    setSubmitting(true)

    createMapping({descriptionGlob, categoryUuid, matchAmount})
      .then(() => {
        setSubmitting(false)
        dispatch(addSnackbar({id: 'createmappingsuccess', text: 'Created mapping.'}))
        onCreate && onCreate()
        setCategoryUuid('')
        setMatchAmount('')
        setDescriptionGlob('')
      })
      .catch(err => {
        console.error(err)
        setSubmitting(false)
        dispatch(addSnackbar({id: 'createmappingfail', text: 'Failed to create mapping.'}))
      })
  }

  return (
    <Paper elevation={3} sx={{py: 2, px: 2}}>
      <Typography variant="h5" component="h5" sx={{mb: 3}}>
        Add Rule
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            name="descriptionGlob"
            fullWidth
            value={descriptionGlob}
            id="descriptionGlob"
            label="Match Description"
            onChange={e => setDescriptionGlob(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="matchAmount"
            fullWidth
            type="number"
            min={0}
            value={matchAmount}
            id="matchAmount"
            label="Match Amount"
            onChange={e => setMatchAmount(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          {categoriesLoading &&
          <Box sx={{ display: 'flex', justifyContent:  'center' }}>
            <CircularProgress />
          </Box>}
          {!categoriesLoading &&
          <FormControl fullWidth>
            <InputLabel id="category">Category</InputLabel>
            <Select
              fullWidth
              labelId="category"
              value={categoryUuid}
              label="Category"
              onChange={e => setCategoryUuid(e.target.value)}
            >
              {categories && categories.map(c => (
                <MenuItem key={c.uuid} value={c.uuid}>
                  <Box display="flex" alignItems="center">
                    {c.color &&
                    <CircleIcon style={{color: c.color}} sx={{mr: 2}} />}
                    <span>{c.name}</span>
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>}
        </Grid>

        <Grid item xs={12}>
          <LoadingButton
            fullWidth
            disabled={(!descriptionGlob && !matchAmount) || !categoryUuid}
            onClick={handleSubmit}
            loading={submitting}
            variant="contained"
          >
            Create
          </LoadingButton>
        </Grid>
      </Grid>
    </Paper>
  )
}
