import * as React from 'react'
import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
// import { useDropzone } from 'react-dropzone'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import CircularProgress from '@mui/material/CircularProgress'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import FilterList from '@mui/icons-material/FilterList'
import MobileDatePicker from '@mui/lab/MobileDatePicker'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DateAdapter from '@mui/lab/AdapterMoment'

import TabPanel from '../../../../components/TabPanel'
import HeadingContainer from '../../../../components/HeadingContainer'
import Copyright from '../../../../components/Copyright'
import FilterContainer from '../../../../components/FilterContainer'

import csvDataToEntries from '../../../../helpers/csvFile/csvDataToEntries'

import searchCategories from '../../../../api/categories/search'
import searchMappings from '../../../../api/mappings/search'
import { addSnackbar } from '../../../../redux/snackbars'
import { setCsvFile } from '../../../../redux/csvFile'
import { setCsvImportOptions } from '../../../../redux/csvImportOptions'

import filterByDates from '../../../../helpers/dates/filterByDates'

import MatchesTable from './components/MatchesTable'
import ErrorsTable from './components/ErrorsTable'
import MissesTable from './components/MissesTable'
import Monthly from './components/Monthly'
import MonthlyAverages from './components/MonthlyAverages'
import WeeklyAverages from './components/WeeklyAverages'
import PieChart from './components/PieChart'
import CSVLoadSettingsDialog from './components/CSVLoadSettingsDialog'

export default function Analysis() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const csvFile = useSelector(state => state.csvFile)
  const csvImportOptions = useSelector(state => state.csvImportOptions)
  const currentUserProfile = useSelector(state => state.currentUserProfile)

  const [errorMessage, setErrorMessage] = React.useState('')
  const [leftTabValue, setLeftTabValue] = React.useState(0)
  const [rightTabValue, setRightTabValue] = React.useState(0)

  // const chartComponentRef = React.useRef(null)

  const [ transactionsShown, setTransactionsShown ] = React.useState(true)
  const [ chartsShown, setChartsShown ] = React.useState(true)

  const [ unmatchedEntries, setUnmatchedEntries ] = React.useState(null)
  const [ matchedEntries, setMatchedEntries ] = React.useState(null)
  const [ errorEntries, setErrorEntries ] = React.useState(null)
  const [ categoriesLoading, setCategoriesLoading ] = React.useState(true)
  const [ categories, setCategories ] = React.useState(null)
  const [ csvLoadSettingsDialogOpen, setCsvLoadSettingsDialogOpen ] = React.useState(false)

  const [ startDate, setStartDate ] = React.useState(null)
  const [ endDate, setEndDate ] = React.useState(null)

  const [ mappingsLoading, setMappingsLoading ] = React.useState(true)
  const [ mappings, setMappings ] = React.useState(null)

  const hasActiveStripeSubscription = currentUserProfile && currentUserProfile.hasActiveStripeSubscription

  const clearAnalysis = () => {
    setUnmatchedEntries(null)
    setMatchedEntries(null)
    setErrorEntries(null)
    setStartDate(null)
    setEndDate(null)
  }

  const resetAll = () => {
    dispatch(setCsvFile(null))
    dispatch(setCsvImportOptions(null))
    clearAnalysis()
  }

  const handleFileSelected = React.useCallback(file => {
    resetAll()

    if (!file) return
    const reader = new FileReader()

    reloadMappings()

    reader.onabort = () => console.log('file reading was aborted')
    reader.onerror = () => console.log('file reading has failed')
    reader.onload = () => {
      dispatch(setCsvFile(reader.result))
      setCsvLoadSettingsDialogOpen(true)
    }
    reader.readAsText(file)
  }, [])

  React.useEffect(() => {
    if (csvLoadSettingsDialogOpen) return clearAnalysis()
    if (!csvFile || !csvImportOptions || !mappings) return clearAnalysis()

    csvDataToEntries({
      csvFile,
      csvImportOptions,
      mappings,
      trialMode: !hasActiveStripeSubscription
    }).then(({
      errorEntries,
      unmatchedEntries,
      matchedEntries,
      startDate,
      endDate,
    }) => {
      setUnmatchedEntries(unmatchedEntries)
      setMatchedEntries(matchedEntries)
      setErrorEntries(errorEntries)
      setStartDate(startDate)
      setEndDate(endDate)
    }).catch(err => {
      console.error(err)
      setErrorMessage('Error fetching analysis data.')
    })
  }, [
    hasActiveStripeSubscription,
    mappings,
    csvFile,
    csvImportOptions,
    csvLoadSettingsDialogOpen
  ])

  const reloadMappings = () => {
    setMappingsLoading(true)
    searchMappings().then((mappings) => {
      setMappingsLoading(false)
      setMappings(mappings)
    }).catch(err => {
      console.error(err)
      setMappingsLoading(false)
      dispatch(addSnackbar({id: 'mappingsearchfail', text: 'Failed to get mappings.'}))
    })
  }

  React.useEffect(() => {
    searchCategories().then((categories) => {
      setCategoriesLoading(false)
      setCategories(categories)
    }).catch(err => {
      console.error(err)
      setCategoriesLoading(false)
      dispatch(addSnackbar({id: 'categorysearchfail', text: 'Failed to get categories.'}))
    })

    reloadMappings()
  }, [])

  // don't do this expensive calc, or render anything if the modal is open
  let filteredMatchedEntries
  let filteredUnmatchedEntries
  let filteredErrorEntries
  if (!csvLoadSettingsDialogOpen) {
    filteredMatchedEntries = matchedEntries && matchedEntries.filter(filterByDates(startDate, endDate))
    filteredUnmatchedEntries = unmatchedEntries && unmatchedEntries.filter(filterByDates(startDate, endDate))
    filteredErrorEntries = errorEntries && errorEntries.filter(filterByDates(startDate, endDate))
  }

  const columnWidth = (chartsShown && transactionsShown) ? 6 : 12

  return (
    <Box>
      {errorMessage &&
      <Stack sx={{ width: '100%' }} spacing={2}>
        <Alert severity="error">{errorMessage}</Alert>
      </Stack>}
      {!hasActiveStripeSubscription &&
      <Stack sx={{ width: '100%' }} spacing={2}>
        <Alert severity="warning">Your account is in trial mode, so only the first 10 rows of your CSV will be imported. Please <Link onClick={() => navigate('/app/account/subscription')}>upgrade your account</Link>.</Alert>
      </Stack>}

      <CSVLoadSettingsDialog
        open={csvLoadSettingsDialogOpen}
        onClose={() => setCsvLoadSettingsDialogOpen(false)}
      />

      <HeadingContainer>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
        >
          Analysis
        </Typography>

        {mappingsLoading &&
        <Box sx={{ display: 'flex', justifyContent:  'center', mx: 2 }}>
          <CircularProgress />
        </Box>}

        <label htmlFor="contained-button-file" style={{display: 'flex'}}>
          <input
            style={{display: 'none'}}
            accept=".csv"
            id="contained-button-file"
            type="file"
            value=''
            onChange={e =>  {handleFileSelected(e.target.files[0])}}
          />

          <Button variant="contained" component="span">
            Load File
          </Button>
        </label>
      </HeadingContainer>

      <Divider />

      <Box sx={{pt: 1}}>

        {matchedEntries &&
        <Box>
          <FilterContainer flexWrap='wrap'>

            <Box display="flex" flexWrap="nowrap" alignItems="center" sx={{mr: 2}}>
              <FilterList sx={{ fontSize: 22 }} />
              <b style={{fontSize: '13px', paddingLeft: '0.15rem', paddingRight: '1rem'}}>Filter</b>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <MobileDatePicker
                  label="Start Date"
                  inputFormat="DD/MM/YYYY"
                  value={startDate}
                  onChange={value => value && setStartDate(value)}
                  renderInput={(params) => <TextField {...params} />}
                />

                <MobileDatePicker
                  label="End Date"
                  inputFormat="DD/MM/YYYY"
                  value={endDate}
                  onChange={(value) => value && setEndDate(value)}
                  renderInput={(params) => <TextField {...params} sx={{ml: 2}} />}
                />
              </LocalizationProvider>
            </Box>

            <FormControlLabel
              label="Show transactions"
              sx={{mr: 2}}
              control={
                <Checkbox
                  checked={transactionsShown}
                  onChange={() => setTransactionsShown(!transactionsShown)}
                />
              }
            />

            <FormControlLabel
              label="Show charts"
              sx={{mr: 2}}
              control={
                <Checkbox
                  checked={chartsShown}
                  onChange={() => setChartsShown(!chartsShown)}
                />
              }
            />
          </FilterContainer>
          <Divider />
        </Box>}


        {!csvFile &&
        <Box display="flex" justifyContent="flex-end" alignItems="flex-end" sx={{mb: 10}}>
          <Typography variant="h4" color="text.secondary" textAlign="right" sx={{pl: 2}}>
            Click here to select a file
          </Typography>
          <img
            alt="Purple arrow"
            src="/img/curved-arrow-purple.svg"
            style={{
              width: '100px',
              marginRight: '50px',
              marginBottom: '17px',
            }}
          />
        </Box>}

        {csvFile &&
        <Grid container spacing={0} wrap="wrap-reverse">
          {/* left hand side */}
          {transactionsShown &&
          <Grid item xs={12} md={columnWidth}>
            {filteredMatchedEntries &&
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={leftTabValue}
                onChange={(e, v) => setLeftTabValue(v)}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
              >
                <Tab label={`Uncategorised ${unmatchedEntries && `(${filteredUnmatchedEntries.length})`}`} />
                <Tab label={`Errors ${errorEntries && `(${filteredErrorEntries.length})`}`} />
                <Tab label={`Categorised ${matchedEntries && `(${filteredMatchedEntries.length})`}`} />
              </Tabs>
            </Box>}

            <TabPanel value={leftTabValue} index={0}>
              {unmatchedEntries &&
              <MissesTable
                entries={filteredUnmatchedEntries}
                onMappingCreated={reloadMappings}
              />}
            </TabPanel>

            <TabPanel value={leftTabValue} index={1}>
              {errorEntries &&
              <ErrorsTable
                entries={filteredErrorEntries}
              />}
            </TabPanel>

            <TabPanel value={leftTabValue} index={2}>
              {filteredMatchedEntries &&
              <MatchesTable
                entries={filteredMatchedEntries}
                categoriesLoading={categoriesLoading}
                categories={categories}
              />}
            </TabPanel>
          </Grid>}

          {/* right hand side */}
          {chartsShown &&
          <Grid item xs={12} md={columnWidth} style={{borderLeft: '1px solid #eee'}}>
            {filteredMatchedEntries &&
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={rightTabValue}
                onChange={(e, v) => setRightTabValue(v)}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
              >
                <Tab label='Pie Chart' />
                <Tab label='Monthly Columns' />
                <Tab label='Monthly Averages' />
                <Tab label='Weekly Averages' />
              </Tabs>
            </Box>}

            <TabPanel value={rightTabValue} index={0}>
              <PieChart
                matchedEntries={matchedEntries}
                filteredMatchedEntries={filteredMatchedEntries}
              />
            </TabPanel>

            <TabPanel value={rightTabValue} index={1}>
              {filteredMatchedEntries &&
              <Monthly
                entries={filteredMatchedEntries}
              />}
            </TabPanel>

            <TabPanel value={rightTabValue} index={2}>
              {filteredMatchedEntries &&
              <MonthlyAverages
                categories={categories}
                filteredMatchedEntries={filteredMatchedEntries}
              />}
            </TabPanel>

            <TabPanel value={rightTabValue} index={3}>
              {filteredMatchedEntries &&
              <WeeklyAverages
                categories={categories}
                filteredMatchedEntries={filteredMatchedEntries}
              />}
            </TabPanel>
          </Grid>}
        </Grid>}
      </Box>

      <Copyright sx={{ pt: 4 }} />
    </Box>
  );
}
