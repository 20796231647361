import * as React from 'react'
import { useNavigate, useLocation } from "react-router-dom"
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import CategoryIcon from '@mui/icons-material/Category'
import CableIcon from '@mui/icons-material/Cable'
import PieChartIcon from '@mui/icons-material/PieChart'
import HelpIcon from '@mui/icons-material/Help'

const NavListItem = (props) => {
  const {
    path
  } = props

  const navigate = useNavigate()
  const location = useLocation()

  return (
    <ListItem selected={location.pathname.startsWith(path)} button onClick={() => navigate(path)}>
      {props.children}
    </ListItem>
  )
}

export const MainListItems = () => {
  return (
    <div>
      <NavListItem path='/app/budget/help'>
        <ListItemIcon>
          <HelpIcon />
        </ListItemIcon>
        <ListItemText primary="Help" />
      </NavListItem>
      <NavListItem path='/app/budget/analysis'>
        <ListItemIcon>
          <PieChartIcon />
        </ListItemIcon>
        <ListItemText primary="Analysis" />
      </NavListItem>
      <NavListItem path='/app/budget/categories'>
        <ListItemIcon>
          <CategoryIcon />
        </ListItemIcon>
        <ListItemText primary="Categories" />
      </NavListItem>
      <NavListItem path='/app/budget/mappings'>
        <ListItemIcon>
          <CableIcon />
        </ListItemIcon>
        <ListItemText primary="Rules" />
      </NavListItem>
    </div>
  )
}