import { createSlice } from '@reduxjs/toolkit'

export const csvFileSlice = createSlice({
  name: 'csvFile',
  initialState: null,
  reducers: {
    setCsvFile: (state, action) => {
      return action.payload
    },
  },
})

export const { setCsvFile } = csvFileSlice.actions

export default csvFileSlice.reducer
