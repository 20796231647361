import axios from '../axios'
import { API_BASE_URL } from '../../consts'

export default function bulkCreateMapping({
  entries,
  categoryUuid,
}) {
  return axios.post(`${API_BASE_URL}/mappings/bulk-create`, {
    entries,
    categoryUuid,
  }).then(response => (response.data))
}
