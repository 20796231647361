import * as React from 'react'

import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'

export default function SubscriptionItem({
  head,
  description,
  tagline,

  onClick
}) {
  return (
    <Paper
      onClick={onClick}
      className='pricing-paper-hover-effect'
      sx={{width: '100%', p: 2}}
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Typography
        component="h5"
        variant="h5"
        align="center"
        // color="text.contrastText"
        style={{
          fontWeight: 800
        }}
      >
        {head}
      </Typography>
      <Typography
        component="h6"
        variant="h6"
        align="center"
        // color="text.contrastText"
        style={{
          fontWeight: 400,
        }}
        sx={{py: 2}}
      >
        {description}
      </Typography>
      <Typography
        component="h6"
        variant="h6"
        align="center"
        // color="text.contrastText"
        style={{
          fontSize: '1rem',
          fontWeight: 400,
          opacity: 0.8
        }}
      >
        {tagline}
      </Typography>
    </Paper>
  )
}
