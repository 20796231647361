import moment from 'moment'
import outmatch from 'outmatch'

import parseCSVIntoCleanArrays from './parseCSVIntoCleanArrays'

import cleanStupidNumber from '../strings/cleanStupidNumber'

export default async function csvDataToEntries({
  csvFile,
  mappings,
  csvImportOptions,
  trialMode
}) {
  let startDate
  let endDate

  let {
    dateColumnName='',
    descriptionColumnName='',
    amountColumnName='',

    dateColumnIndex=0,
    descriptionColumnIndex=1,
    amountColumnIndex=2,

    dateFormat='',

    hasHeaderRow=false,
    negativeValuesOnly=false
  } = csvImportOptions

  let rows = await parseCSVIntoCleanArrays({csvFile, lowercase: false})

  const headerRowLowercase = rows.shift().map(s => s.toLowerCase())

  if (hasHeaderRow) {
    dateColumnIndex = headerRowLowercase.indexOf(dateColumnName.toLowerCase())
    descriptionColumnIndex = headerRowLowercase.indexOf(descriptionColumnName.toLowerCase())
    amountColumnIndex = headerRowLowercase.indexOf(amountColumnName.toLowerCase())
  }

  const objects = []

  if (trialMode) rows = rows.slice(0, 10)

  // cast rows to our datastructure
  for (const rowArray of rows) {
    const date = rowArray[dateColumnIndex]
    const description = rowArray[descriptionColumnIndex]
    const debit = rowArray[amountColumnIndex]

    // find errors in data
    let error

    if (!date)
      error = 'Missing date.'

    // factor in negative values only
    if (negativeValuesOnly && (!debit || Number(debit) > 0))
      continue

    const castedDate = new moment(date, dateFormat)

    if (!castedDate.isValid()) {
      error = 'Invalid date.'
    } else {
      // capture start and end dates from data
      if (!startDate || startDate > castedDate)
        startDate = castedDate
      if (!endDate || endDate < castedDate)
        endDate = castedDate
    }
    if (!description)
      error = 'Missing description.'

    if (!debit) {
      // can't decide whether to show error or ignore this...
      // for sake of westpac, don't show error
      continue
      // error = 'Missing debit amount.'
    }

    objects.push({
      date,
      castedDate,
      description,
      debit,
      error
    })
  }

  const mappedData = objects.map(d => {
    for (const mapping of mappings) {
      let isMatchDescription = false
      if (mapping.descriptionGlob) {
        const dMatcher = outmatch(mapping.descriptionGlob, false)
        isMatchDescription = dMatcher(d.description)
      }
      const isMatchAmount = (!mapping.matchAmount) || ((Math.round(Math.abs(d.debit) * 100)) === mapping.matchAmount)

      const isMatch = isMatchAmount && isMatchDescription

      if (isMatch) {
        d.category = {
          uuid: mapping.category && mapping.category.uuid,
          color: mapping.category && mapping.category.color,
          name: mapping.category && mapping.category.name,
        }
        break
      }
    }

    return d
  })

  const errorEntries = []
  const matchedEntries = []
  const unmatchedEntries = []

  mappedData.sort((a, b) => {
    if (a.castedDate < b.castedDate) return 1
    if (a.castedDate > b.castedDate) return -1
    return 0
  }).forEach(entry => {
    const y = cleanStupidNumber(entry.debit)

    // handle errors
    if (entry.error) return errorEntries.push(entry)
    if (isNaN(y)) {
      entry.error = '`debit` was not a number.'
      return errorEntries.push(entry)
    }

    if (!entry.category) {
      return unmatchedEntries.push(entry)
    }

    matchedEntries.push(entry)
  })

  return {
    errorEntries,
    matchedEntries,
    unmatchedEntries,
    startDate,
    endDate
  }
}
