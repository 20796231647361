import * as React from 'react'

import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Pagination from '@mui/material/Pagination'
import Typography from '@mui/material/Typography'

const PER_PAGE = 50

export default function ErrorsTable({
  entries=[],
}) {
  const [ page, setPage ] = React.useState(1)

  const totalPages = Math.ceil(entries.length / PER_PAGE)

  const filteredPagedEntries = entries.slice((page-1) * PER_PAGE, page * PER_PAGE)

  React.useEffect(() => {
    if (page > totalPages) setPage(1)
  }, [page, totalPages])

  if (entries.length === 0) return <Typography variant="p">No errors.</Typography>

  return (
    <Paper elevation={3} style={{overflowX: 'scroll'}}>

      {totalPages > 1 &&
      <Pagination
        count={totalPages}
        page={page}
        sx={{pt: 2}}
        onChange={(_, v) => setPage(v)}
      />}

      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left"><b>Date</b></TableCell>
            <TableCell align="left"><b>Description</b></TableCell>
            <TableCell align="left"><b>Error</b></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredPagedEntries.map((entry, i) => (
            <TableRow
              key={`${entry.castedDate}${entry.description}${entry.amount}${i}`}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left">{entry.castedDate ? entry.castedDate.format('DD/MM/YYYY') : <i>None</i>}</TableCell>
              <TableCell align="left" style={{whiteSpace: 'pre-wrap'}}>{entry.description}</TableCell>
              <TableCell align="left">{entry.error}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  )
}
