import * as React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import mdTheme from '../../../../lib/mdTheme'

const CIRCLE_SIZE = "4rem";

export default function NumberCircle({
  number,
  color
}) {
  return (
    <div
      style={{
        borderRadius: '50%',
        border: `0.25rem solid ${color || mdTheme.palette.primary.main}`,
        height: CIRCLE_SIZE,
        width: CIRCLE_SIZE,
        textAlign: 'center'
      }}
    >
      <Typography
        variant='h6'
        style={{
          color: color || mdTheme.palette.primary.main,
          fontSize: '2.3rem',
          fontWeight: 600,
          marginTop: '-3%'
        }}
      >
        {number}
      </Typography>
    </div>
  )
}
