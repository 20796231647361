import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LoadingButton from '@mui/lab/LoadingButton'
import Box from '@mui/material/Box'
import DialogActions from '@mui/material/DialogActions'
import Typography from '@mui/material/Typography'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Stack from '@mui/material/Stack'
import Alert from '@mui/material/Alert'
import Dialog from '@mui/material/Dialog'
import Grid from '@mui/material/Grid'

// import createMapping from '../../../../../../api/mappings/create'
// import searchCategories from '../../../../../../api/categories/search'
import { setCsvImportOptions } from '../../../../../../redux/csvImportOptions'

import parseCSVIntoCleanArrays from '../../../../../../helpers/csvFile/parseCSVIntoCleanArrays'
import { bankToImportOptions } from '../../../../../../helpers/csvFile/presetImportOptions'
import PresetImportOptionsPicker from '../../../../../../components/PresetImportOptionsPicker'
import CustomCSVImportOptionsForm from '../../../../../../components/CustomCSVImportOptionsForm'

import CSVFilePreview from '../CSVFilePreview'
import ImportErrors from '../ImportErrors'
import findErrorsInCSVImportSettings from '../../../../../../helpers/csvFile/findErrorsInCSVImportSettings'

function usePrevious(value) {
  const ref = React.useRef()
  React.useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export default function CSVLoadSettingsDialog({
  open,
  onClose
}) {
  const dispatch = useDispatch()
  const csvFile = useSelector(state => state.csvFile)
  const csvImportOptions = useSelector(state => state.csvImportOptions)

  const [bank, setBank] = React.useState('')
  // const [submitting, setSubmitting] = React.useState(false)
  const [csvFileRows, setCsvFileRows] = React.useState(null)

  React.useEffect(() => {
    if (!csvFile) return setCsvFileRows(null)
    parseCSVIntoCleanArrays({csvFile, lowercase: true}).then(rows => {
      setCsvFileRows(rows)
    })
  }, [csvFile])

  const handleClose = () => {
    setBank('')
    onClose()
  }

  // point of this:
  // - when you pick current as your first choice, default to bankToImportOptions.custom
  // - when you switch to current from another bank, don't reset fields to bankToImportOptions.custom
  const prevBank = usePrevious(bank)
  React.useEffect(() => {
    if ((bank === 'custom' && !prevBank) || bank !== 'custom')
      return dispatch(setCsvImportOptions(bankToImportOptions[bank]))
  }, [dispatch, prevBank, bank])

  const {
    dateColumnFound,
    descriptionColumnFound,
    amountColumnFound,

    dateColumnIndexValid,
    descriptionColumnIndexValid,
    amountColumnIndexValid,

    dateFormatValid,

    importErrors,
  } = findErrorsInCSVImportSettings(csvFileRows, csvImportOptions)

  return (
    <Box component="form">
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Load CSV
        </DialogTitle>

        {!csvFile &&
        <DialogContent>
          <Typography variant="p" component="p">
            Please select a CSV file.
          </Typography>
        </DialogContent>}

        {csvFile &&
        <DialogContent>
          <Grid container spacing={2} sx={{mb: 3}}>
            <Grid item xs={12}>
              <Typography component="p" variant="p">
                Configure the import options for your bank statement here.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <PresetImportOptionsPicker
                value={bank}
                onChange={setBank}
              />
            </Grid>
          </Grid>

          {bank && importErrors.length > 0 &&
          <Box sx={{mb: 4}}>
            <ImportErrors errors={importErrors} />
          </Box>}

          {bank && importErrors.length === 0 &&
          <Box sx={{mb: 4}}>
            <ImportErrors successes={['Your file can be imported.']} />
          </Box>}

          {bank && (bank === 'custom' || (importErrors && importErrors.length > 0)) &&
          <CustomCSVImportOptionsForm
            value={csvImportOptions}
            onChange={csvImportOptions => {dispatch(setCsvImportOptions(csvImportOptions)) ; setBank('custom')}}

            dateColumnFound={dateColumnFound}
            descriptionColumnFound={descriptionColumnFound}
            amountColumnFound={amountColumnFound}

            dateColumnIndexValid={dateColumnIndexValid}
            descriptionColumnIndexValid={descriptionColumnIndexValid}
            amountColumnIndexValid={amountColumnIndexValid}

            dateFormatValid={dateFormatValid}
          />}

          <Typography component="p" variant="p" sx={{mt: 4}}>
            A preview of your statement is shown below:
          </Typography>

          <CSVFilePreview />
        </DialogContent>}

        <DialogActions>
          {csvFile &&
          <LoadingButton
            // loading={submitting}
            onClick={handleClose}
            variant="contained"
          >
            Create
          </LoadingButton>}
          <LoadingButton onClick={onClose}>Cancel</LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
