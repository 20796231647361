import * as React from 'react'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import CircleIcon from '@mui/icons-material/Circle'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import CircularProgress from '@mui/material/CircularProgress'
import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Pagination from '@mui/material/Pagination'


import Copyright from '../../../../components/Copyright'
import HeadingContainer from '../../../../components/HeadingContainer'

import searchCategories from '../../../../api/categories/search'

import CreateCategory from './components/CreateCategory'
import EditCategoryDialog from './components/EditCategoryDialog'

const PER_PAGE = 10

export default function Categories() {
  const navigate = useNavigate()

  const [categoriesLoading, setCategoriesLoading] = React.useState(true)
  const [categories, setCategories] = React.useState(null)
  const [errorMessage, setErrorMessage] = React.useState('')

  const [editCategoryDialogOpen, setEditCategoryDialogOpen] = React.useState(false)
  const [categoryToEdit, setCategoryToEdit] = React.useState(null)

  const [page, setPage] = React.useState(1)
  const [totalPages, setTotalPages] = React.useState(0)

  const handleSearchCategories = () => {
    setCategoriesLoading(true)
    searchCategories({
      page,
      perPage: PER_PAGE
    }).then((categories) => {
      setCategories(categories)
      setTotalPages(totalPages)
      setCategoriesLoading(false)
    }).catch(err => {
      console.error(err)
      setCategoriesLoading(false)
      setErrorMessage((err.response && err.response.data && err.response.data.error) || err.message)
    })
  }

  React.useEffect(() => {
    handleSearchCategories()
  }, [page])

  return (
    <div>
      {errorMessage &&
      <Stack sx={{ width: '100%' }} spacing={2}>
        <Alert severity="error">{errorMessage}</Alert>
      </Stack>}

      <HeadingContainer>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
        >
          Categories
        </Typography>
      </HeadingContainer>

      <Divider />

      <Container maxWidth='md' sx={{mb: 3, pt: 3}}>
        <CreateCategory onCreate={handleSearchCategories} />

        {categoriesLoading &&
        <Box sx={{ display: 'flex', justifyContent:  'center', mt: 2 }}>
          <CircularProgress />
        </Box>}

        {!categoriesLoading &&
        <Box maxWidth={false}>
          {totalPages > 1 &&
          <Pagination
            sx={{mb: 3}}
            count={totalPages}
            page={page}
            onChange={(_, v) => setPage(v)}
          />}

          <EditCategoryDialog
            open={editCategoryDialogOpen}
            handleClose={() => setEditCategoryDialogOpen(false)}
            category={categoryToEdit}
            onSubmit={handleSearchCategories}
          />
        </Box>}
      </Container>

      {!categoriesLoading && categories && categories.length > 0 &&
      <Container maxWidth='md' sx={{mb: 3, pt: 3}}>
        <Paper elevation={3} style={{overflowX: 'scroll'}}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left"><b>Name</b></TableCell>
                <TableCell align="right"></TableCell>
                {/* <TableCell align="right" /> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {categories && categories.map((category) => (
                <TableRow
                  key={category.uuid}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="left">
                    <Box display="flex" alignItems="center">
                      {category.color && <CircleIcon style={{color: category.color}} sx={{mr: 2}}/>}<span>{category.name}</span>
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={() => {setCategoryToEdit(category) ; setEditCategoryDialogOpen(true)}}
                    >
                      Edit
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </Container>}

      <Copyright sx={{ pt: 4 }} />
    </div>
  );
}
