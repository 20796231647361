export default function entriesToMonthlyAveragesTable(entries, categories) {
  let earliestDate, latestDate
  let totalPerCategory = {}
  let categoryDict = {}

  categories.forEach(c => {
    categoryDict[c.uuid] = c
  })

  entries.forEach(e => {
    const categoryUuid = e.category && e.category.uuid
    if (!categoryUuid) return console.error('[entriesToWeeklyAveragesTable] No category name')

    if (!totalPerCategory[categoryUuid]) totalPerCategory[categoryUuid] = 0

    if (!e.debit) return console.error('[entriesToWeeklyAveragesTable] No debit value')
    const debit = Math.abs(Number(e.debit))
    if (isNaN(debit)) return console.error('[entriesToWeeklyAveragesTable] Debit was NaN')

    totalPerCategory[categoryUuid] += debit

    if (!latestDate || e.castedDate > latestDate) latestDate = e.castedDate
    if (!earliestDate || e.castedDate < earliestDate) earliestDate = e.castedDate
  })

  if (!earliestDate || !latestDate) return []

  let months = (latestDate.diff(earliestDate, 'weeks', true) + 1)

  const data = Object.keys(totalPerCategory).map(k => (
    {
      categoryName: categoryDict[k].name,
      categoryColor: categoryDict[k].color,
      categoryUuid: k,
      amount: totalPerCategory[k] / months
    }
  )).filter(n => (n.categoryName !== 'Ignore')).sort((a, b) => (b.amount - a.amount))

  if (data.length > 0) {
    let totalAmount = 0
    data.forEach(d => {totalAmount += (d.amount || 0)})

    data.push({
      categoryName: 'Total',
      categoryUuid: 'total',
      amount: totalAmount
    })
  }

  return data
}
