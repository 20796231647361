import * as React from 'react'
import { useNavigate, useLocation } from "react-router-dom"
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import PersonIcon from '@mui/icons-material/Person'
import InventoryIcon from '@mui/icons-material/Inventory'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import PasswordIcon from '@mui/icons-material/Password'
import LocalActivityIcon from '@mui/icons-material/LocalActivity'

const NavListItem = (props) => {
  const {
    path
  } = props

  const navigate = useNavigate()
  const location = useLocation()

  return (
    <ListItem selected={location.pathname.startsWith(path)} button onClick={() => navigate(path)}>
      {props.children}
    </ListItem>
  )
}

export const MainListItems = () => {
  return (
    <div>
      <NavListItem path='/app/account/profile'>
        <ListItemIcon>
          <PersonIcon />
        </ListItemIcon>
        <ListItemText primary="Profile" />
      </NavListItem>
      <NavListItem path='/app/account/subscription'>
        <ListItemIcon>
          <LocalActivityIcon />
        </ListItemIcon>
        <ListItemText primary="Subscription" />
      </NavListItem>
      <NavListItem path='/app/account/change-password'>
        <ListItemIcon>
          <PasswordIcon />
        </ListItemIcon>
        <ListItemText primary="Change Password" />
      </NavListItem>
    </div>
  )
}