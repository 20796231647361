import * as React from 'react'
import { useDispatch } from 'react-redux'
import LoadingButton from '@mui/lab/LoadingButton'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import InputAdornment from '@mui/material/InputAdornment'
import Grid from '@mui/material/Grid'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import CircularProgress from '@mui/material/CircularProgress'
import CircleIcon from '@mui/icons-material/Circle'

import updateMapping from '../../../../../../api/mappings/update'
import deleteMapping from '../../../../../../api/mappings/delete'
import { addSnackbar } from '../../../../../../redux/snackbars'

export default function EditMappingDialog({
  open,
  handleClose,
  onCreate,
  categories,
  categoriesLoading,
  mapping
}) {
  const dispatch = useDispatch()

  const [submitting, setSubmitting] = React.useState(false)

  const [matchAmount, setMatchAmount] = React.useState('')
  const [categoryUuid, setCategoryUuid] = React.useState('')
  const [descriptionGlob, setDescriptionGlob] = React.useState('')
  const [deleting, setDeleting] = React.useState(false)

  const handleDelete = () => {
    setDeleting(true)
    deleteMapping(mapping.uuid).then(() => {
      setDeleting(false)
      dispatch(addSnackbar({id: 'deletemappingsuccess', text: 'Successfully deleted.'}))
      setCategoryUuid('')
      setDescriptionGlob('')
      setMatchAmount('')
      handleClose()
      onCreate && onCreate()
    }).catch(err => {
      console.error(err)
      setDeleting(false)
      dispatch(addSnackbar({id: 'deletemappingfail', text: 'Failed to delete mapping.'}))
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    setSubmitting(true)

    updateMapping(mapping.uuid, {descriptionGlob, categoryUuid, matchAmount})
      .then(() => {
        setSubmitting(false)
        dispatch(addSnackbar({id: 'createmappingsuccess', text: 'Created mapping.'}))
        onCreate && onCreate()
        handleClose()
        setCategoryUuid('')
        setDescriptionGlob('')
        setMatchAmount('')
      })
      .catch(err => {
        console.error(err)
        setSubmitting(false)
        dispatch(addSnackbar({id: 'createmappingfail', text: 'Failed to create mapping.'}))
      })
  }

  React.useEffect(() => {
    if (!mapping) return
    setCategoryUuid(mapping.categoryUuid)
    mapping.matchAmount && setMatchAmount(mapping.matchAmount / 100)
    setDescriptionGlob(mapping.descriptionGlob)
  }, [mapping])

  return (
    <Box component="form">
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Edit Rule
        </DialogTitle>
        <DialogContent>
          <TextField
            name="descriptionGlob"
            required
            sx={{mt: 1}}
            fullWidth
            id="descriptionGlob"
            value={descriptionGlob}
            label="Match Description"
            onChange={e => setDescriptionGlob(e.target.value)}
          />
          <Grid item xs={12}>
            <TextField
              name="matchAmount"
              fullWidth
              sx={{mt: 2}}
              type="number"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              onKeyDown={(e) =>["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
              value={matchAmount}
              id="matchAmount"
              label="Match Amount"
              onChange={e => {
                const val = e.target.value

                if (val === '') return setMatchAmount('')
                if (isNaN(val)) return setMatchAmount(0)
                if (val < 0) return setMatchAmount(0)

                const cleanedVal = Number(Number(val).toFixed(2))

                setMatchAmount(cleanedVal)
              }}
            />
          </Grid>

          {categoriesLoading &&
          <Box sx={{ display: 'flex', justifyContent:  'center' }}>
            <CircularProgress />
          </Box>}

          {!categoriesLoading &&
          <FormControl sx={{my: 3}} fullWidth>
            <InputLabel id="category">Category</InputLabel>
            <Select
              fullWidth
              labelId="category"
              value={categoryUuid}
              label="Category"
              onChange={e => setCategoryUuid(e.target.value)}
            >
              {categories && categories.map(c => (
                <MenuItem key={c.uuid} value={c.uuid}>
                  <Box display="flex" alignItems="center">
                    {c.color &&
                    <CircleIcon style={{color: c.color}} sx={{mr: 2}} />}
                    <span>{c.name}</span>
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>}

          <LoadingButton
            fullWidth
            loading={deleting}
            onClick={handleDelete}
            variant="outlined"
            color="error"
          >
            Delete Mapping
          </LoadingButton>
        </DialogContent>

        <DialogActions>
          <LoadingButton loading={submitting} onClick={handleSubmit}>Save</LoadingButton>
          <LoadingButton onClick={handleClose}>Cancel</LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
