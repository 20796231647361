import axios from '../axios'
import { API_BASE_URL } from '../../consts'

export default function updateMapping(mappingUuid, {
  categoryUuid,
  descriptionGlob,
  matchAmount
}) {
  const parsedMatchAmount = (matchAmount !== undefined && matchAmount !== '') ? (matchAmount * 100) : undefined

  return axios.patch(`${API_BASE_URL}/mappings/${mappingUuid}`, {
    categoryUuid,
    descriptionGlob,
    matchAmount: (parsedMatchAmount || undefined)
  }).then(response => (response.data))
}
