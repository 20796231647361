import * as React from 'react'
import { useDispatch } from 'react-redux'
import LoadingButton from '@mui/lab/LoadingButton'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import CircleIcon from '@mui/icons-material/Circle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import InputAdornment from '@mui/material/InputAdornment'
import Dialog from '@mui/material/Dialog'
import Grid from '@mui/material/Grid'
import CircularProgress from '@mui/material/CircularProgress'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'

import createMapping from '../../../../../../api/mappings/create'
import searchCategories from '../../../../../../api/categories/search'
import { addSnackbar } from '../../../../../../redux/snackbars'
// import centsToDollarString from '../../../../../../helpers/money/centsToDollarString'
import dollarsToDollarString from '../../../../../../helpers/money/dollarsToDollarString'

export default function CreateMappingDialog({
  open,
  handleClose,
  initialMapping,
  onSubmit
}) {
  const dispatch = useDispatch()

  const [submitting, setSubmitting] = React.useState(false)

  const [matchSpecificTransaction, setMatchSpecificTransaction] = React.useState(false)
  const [categoriesLoading, setCategoriesLoading] = React.useState(true)
  const [shouldMatchAmount, setShouldMatchAmount] = React.useState(false)
  const [categories, setCategories] = React.useState(null)

  const [matchAmount, setMatchAmount] = React.useState('')
  const [categoryUuid, setCategoryUuid] = React.useState('')
  const [descriptionGlob, setDescriptionGlob] = React.useState('')

  React.useEffect(() => {
    if (!initialMapping) return
    setDescriptionGlob(initialMapping.descriptionGlob)
    setMatchAmount(initialMapping.matchAmount)
  }, [initialMapping])

  React.useEffect(() => {
    searchCategories().then((categories) => {
      setCategoriesLoading(false)
      setCategories(categories)
    }).catch(err => {
      console.error(err)
      setCategoriesLoading(false)
      dispatch(addSnackbar({id: 'categorysearchfail', text: 'Failed to get categories.'}))
    })
  }, [])

  const handleSubmit = (event) => {
    event.preventDefault()
    setSubmitting(true)

    const mappingToCreate = {
      descriptionGlob,
      categoryUuid,
      matchAmount: shouldMatchAmount ? matchAmount : undefined
    }

    if (matchSpecificTransaction) {
      mappingToCreate.descriptionGlob = initialMapping.descriptionGlob
      mappingToCreate.matchAmount = initialMapping.matchAmount
    }

    createMapping(mappingToCreate).then(() => {
      setSubmitting(false)
      dispatch(addSnackbar({id: 'createmappingsuccess', text: 'Created mapping.'}))
      handleClose()
      setMatchSpecificTransaction(false)
      setShouldMatchAmount(false)
      onSubmit && onSubmit()
    }).catch(err => {
      console.error(err)
      setSubmitting(false)
      dispatch(addSnackbar({id: 'createmappingfail', text: 'Failed to update category.'}))
    })
  }

  return (
    <Box component="form">
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Create Rule
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControlLabel
                label={'Match this specific transaction?'}
                control={<Checkbox checked={matchSpecificTransaction} onChange={e => setMatchSpecificTransaction(e.target.checked)} />}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="descriptionGlob"
                fullWidth
                id="descriptionGlob"
                label="Match Description"
                value={descriptionGlob}
                onChange={e => setDescriptionGlob(e.target.value)}
                disabled={matchSpecificTransaction}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                label={`Match amount (${matchAmount && dollarsToDollarString(matchAmount)})?`}
                control={
                  <Checkbox
                    disabled={matchSpecificTransaction}
                    checked={shouldMatchAmount || matchSpecificTransaction}
                    onChange={e => setShouldMatchAmount(e.target.checked)}
                  />
                }
              />
            </Grid>

            <Grid item xs={12}>
              {categoriesLoading && <CircularProgress />}
              {!categoriesLoading &&
              <FormControl fullWidth>
                <InputLabel id="category">Category</InputLabel>
                <Select
                  fullWidth
                  required
                  labelId="category"
                  value={categoryUuid}
                  label="Category"
                  onChange={e => setCategoryUuid(e.target.value)}
                >
                  {categories && categories.map(c => (
                    <MenuItem key={c.uuid} value={c.uuid}>
                      <Box display="flex" alignItems="center">
                        {c.color &&
                        <CircleIcon style={{color: c.color}} sx={{mr: 2}} />}
                        <span>{c.name}</span>
                      </Box>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>}
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <LoadingButton disabled={(!descriptionGlob && !matchAmount) || !categoryUuid} loading={submitting} onClick={handleSubmit}>Create</LoadingButton>
          <LoadingButton onClick={handleClose}>Cancel</LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
