import axios from '../axios'
import { API_BASE_URL } from '../../consts'

export default function createMapping({
  descriptionGlob,
  categoryUuid,
  matchAmount
}) {
  const parsedMatchAmount = (matchAmount !== undefined && matchAmount !== '') ? (matchAmount * 100) : undefined

  return axios.post(`${API_BASE_URL}/mappings`, {
    descriptionGlob,
    categoryUuid,
    matchAmount: (parsedMatchAmount || undefined)
  }).then(response => (response.data))
}
