const ing = {
  dateColumnName: 'Date',
  descriptionColumnName: 'Description',
  amountColumnName: 'Debit',

  dateColumnIndex: undefined,
  descriptionColumnIndex: undefined,
  amountColumnIndex: undefined,

  dateFormat: 'DD/MM/YYYY',

  hasHeaderRow: true,
  negativeValuesOnly: false
}

export default ing
