import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'

import Copyright from '../Copyright'
import RouterLink from '../RouterLink'

function FooterLink({
  to,
  href,
  children
}) {
  return (
    <Box className="footer-item-link" sx={{mb: 2, width: '100%'}}>
      {href &&
      <a style={{textAlign: 'center', display: 'block'}} href={href}>{children}</a>}
      {to &&
      <RouterLink href={to} underline="hover" color="inherit" style={{width: '100%'}}>
        <span style={{textAlign: 'center', display: 'block'}}>
          {children}
        </span>
      </RouterLink>}
    </Box>
  )
}

function FooterColumn(props) {
  return (
    <Grid
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="flex-start"
      item
      xs={4}
    >
      {props.children}
    </Grid>
  )
}

export default function Footer(props) {
  return (
    <Container maxWidth="md" sx={{pb: 6}}>
      <Box sx={{mt: 10, mb: 2}}>
        <Grid container spacing={2}>
          <FooterColumn>
            <FooterLink to="/app/home">
              Home
            </FooterLink>
            <FooterLink to="/app/signin">
              Sign In
            </FooterLink>
            <FooterLink to="/app/signup">
              Sign Up
            </FooterLink>
          </FooterColumn>
          <FooterColumn>
            <FooterLink to="/app/contact">
              Contact
            </FooterLink>
            <FooterLink to="/app/privacy">
              Privacy Policy
            </FooterLink>
            <FooterLink to="/app/terms">
              Terms of Use
            </FooterLink>
          </FooterColumn>
          <FooterColumn>
            <FooterLink href="https://guides.budgetanalyser.com">
              Guides
            </FooterLink>
          </FooterColumn>
        </Grid>
      </Box>

      <Copyright />
    </Container>
  );
}
