import { parse } from '../../lib/csv-parse/dist/esm/index.js'

import stripString from '../strings/stripString'

export default async function parseCSVIntoCleanArrays({csvFile, lowercase} = {}) {
  if (!csvFile) return []

  return new Promise((resolve, reject) => {
    parse(csvFile, (err, records) => {
      if (err) return reject(err)

      return resolve(records.map(row => (row.map(s => {
        if (lowercase) return stripString(s).toLowerCase()
        return stripString(s)
      }))))
    })
  })
}
