import * as React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import { ThemeProvider } from '@mui/material/styles'
import Toolbar from '@mui/material/Toolbar'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

import AppBar from '../../components/AppBar'
import Copyright from '../../components/Copyright'

import mdTheme from '../../lib/mdTheme'

export default function CompleteConfirmEmail() {
  const navigate = useNavigate()
  const currentUserProfile = useSelector((state) => state.currentUserProfile)

  return (
    <ThemeProvider theme={mdTheme}>
      <AppBar title="Confirm Email"/>

      <Toolbar />

      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            A confirmation email has been sent. This may take a minute to arrive,
            and please check your junk mail.
          </Typography>
        </Box>

        <Stack
          sx={{ pt: 4 }}
          direction="row"
          spacing={2}
          justifyContent="center"
        >
          <Button
            onClick={() => currentUserProfile ? navigate('/app/budget/help') : navigate('/app/signin')}
            variant="contained"
          >
            Enter Account <ArrowForwardIosIcon style={{fontSize: '0.75rem'}} sx={{ml: 1}} />
          </Button>
        </Stack>

        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  )
}
