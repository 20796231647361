import getCheckoutSession from '../api/stripe/getCheckoutSession'
import getPortalSession from '../api/stripe/getPortalSession'

import { loadStripe } from '@stripe/stripe-js'

const stripe = loadStripe(window.STRIPE_PUBLIC_KEY)

export const redirectToCheckout = async ({sku}) => {
  return await getCheckoutSession({sku}).then(async checkoutSession => {
    (await stripe).redirectToCheckout({sessionId: checkoutSession.id})
  })
}

export const redirectToPortal = async () => {
  return await getPortalSession().then(async portalSession => {
    window.open(portalSession.url, "_self")
  })
}

export default stripe
