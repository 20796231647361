import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { ThemeProvider } from '@mui/material/styles'
import Button from '@mui/material/Button'
import Badge from '@mui/material/Badge'
import IconButton from '@mui/material/IconButton'
import Link from '@mui/material/Link'
// import PersonIcon from '@mui/icons-material/Person'
import MenuIcon from '@mui/icons-material/Menu'

import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

import logout from '../../api/auth/logout'
import { setCurrentUserProfile } from '../../redux/currentUserProfile'
import { addSnackbar } from '../../redux/snackbars'
import { setCsvFile } from '../../redux/csvFile'
import { setCsvImportOptions } from '../../redux/csvImportOptions'

import mdTheme from '../../lib/mdTheme'

export default function AppBarItems(props) {
  const typographyColor = 'inherit'

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [anchorEl, setAnchorEl] = React.useState(null)

  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null);
  }

  const handleLogoutClick = () => {
    logout()
      .then(() => {
        handleClose()
        dispatch(setCurrentUserProfile(null))
        dispatch(setCsvFile(null))
        dispatch(setCsvImportOptions(null))
        dispatch(addSnackbar({id: 'logoutsuccess', text: 'Log out successful.'}))
        navigate("/app")
      })
      .catch(err => {
        console.error(err)
        handleClose()
        dispatch(setCurrentUserProfile(null))
        navigate("/app")
      })
  }

  const currentUserProfile = useSelector((state) => state.currentUserProfile)

  return (
    <ThemeProvider theme={mdTheme}>
      {currentUserProfile &&
      <IconButton
        onClick={e => handleClick(e)}
        color="inherit"
      >
        <Badge color="secondary">
          <MenuIcon color={typographyColor} />
        </Badge>
      </IconButton>}

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => navigate('/app')}>Home</MenuItem>
        <Link className="menu-item-link" href="https://guides.budgetanalyser.com">
          <MenuItem>Guides</MenuItem>
        </Link>
        {currentUserProfile &&
        <MenuItem onClick={() => navigate('/app/budget/help')}>Budget</MenuItem>}
        {currentUserProfile && currentUserProfile.isAdmin &&
        <MenuItem onClick={() => navigate('/app/admin/users')}>Admin</MenuItem>}
        {currentUserProfile &&
        <MenuItem onClick={() => navigate('/app/account/profile')}>Account</MenuItem>}
        {currentUserProfile &&
        <MenuItem onClick={handleLogoutClick}>Logout</MenuItem> }
      </Menu>

      {!currentUserProfile &&
      <Button color={typographyColor} onClick={() => navigate('/app/signin')}>Sign In</Button>}

      {!currentUserProfile &&
      <Button color={typographyColor} onClick={() => navigate('/app/signup')}>Sign Up</Button>}
    </ThemeProvider>
  );
}
