import * as React from 'react'
import { useSelector } from 'react-redux'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import Typography from '@mui/material/Typography'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'

import parseCSVIntoCleanArrays from '../../../../../../helpers/csvFile/parseCSVIntoCleanArrays'

export default function CSVFilePreview() {
  const csvFile = useSelector(state => state.csvFile)
  const csvImportOptions = useSelector(state => state.csvImportOptions)

  const {
    dateColumnName,
    descriptionColumnName,
    amountColumnName,
    hasHeaderRow
  } = (csvImportOptions || {})

  const [csvFileRows, setCsvFileRows] = React.useState(null)

  const matchesOne = (val) => {
    const lowerVal = val && val.toLowerCase()
    if (lowerVal === (dateColumnName && dateColumnName.toLowerCase())) return true
    if (lowerVal === (descriptionColumnName && descriptionColumnName.toLowerCase())) return true
    if (lowerVal === (amountColumnName && amountColumnName.toLowerCase())) return true
    return false
  }

  React.useEffect(() => {
    if (!csvFile) return setCsvFileRows(null)
    parseCSVIntoCleanArrays({csvFile}).then(rows => {
      setCsvFileRows(rows)
    })
  }, [csvFile])

  const headerRows = hasHeaderRow ? (csvFileRows|| []).slice(0, 1) : []
  const contentRows = hasHeaderRow ? (csvFileRows|| []).slice(1, 5) : (csvFileRows|| []).slice(0, 4)

  return (
    <Paper elevation={3} sx={{mt: 2}} style={{overflowX: 'scroll'}}>
      <Table size='small'>
        <TableBody>

          {headerRows.map((row, i) => (
            <TableRow
              // TODO: stringify bad for performance. I guess it's okay just for preview.
              key={`${JSON.stringify(row)}${i}`}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              {row.map((c, j) => (
                <TableCell key={`${c}${j}`} align="left">
                  <Typography
                    variant="p"
                    color={matchesOne(c) ? "primary" : "inherit"}
                    style={{fontWeight: 600}}
                  >
                    {c}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          ))}

          {contentRows.map((row) => (
            <TableRow
              key={JSON.stringify(row)}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              {row.map((c, i) => (<TableCell key={`${c}${i}`} align="left">{c}</TableCell>))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  )
}
