import * as React from 'react'

import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import CircleIcon from '@mui/icons-material/Circle'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'

import { mappings } from '../exampleData'
import dollarsToDollarString from '../../../../helpers/money/dollarsToDollarString'

export default function RulesList() {
  return (
    <Paper elevation={3} style={{overflowX: 'scroll'}}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left"><b>Match Description</b></TableCell>
            <TableCell align="left"><b>Category</b></TableCell>
            {/* <TableCell align="left"><b>Amount</b></TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {mappings && mappings.map((mapping) => (
            <TableRow
              key={mapping.descriptionGlob}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left">
                {mapping.descriptionGlob}
              </TableCell>
              <TableCell align="left">
                <Box display="flex" alignItems="center">
                  {mapping.category.color && <CircleIcon style={{color: mapping.category.color}} sx={{mr: 2}}/>}
                  <span>{mapping.category.name}</span>
                </Box>
              </TableCell>
              {/* <TableCell align="left">
                {dollarsToDollarString(mapping.amount)}
              </TableCell> */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  )
}
