import axios from '../axios'
import { API_BASE_URL } from '../../consts'

export default function searchMappings({
  categoryUuid
}={}) {
  const params = new URLSearchParams()
  categoryUuid && params.set('categoryUuid', categoryUuid)

  return axios.get(`${API_BASE_URL}/mappings?${params.toString()}`).then(response => (response.data))
}
