/* eslint-disable no-template-curly-in-string */

// series data is [{y, categoryUuid, color, name}]
export default function seriesDataToChartJSPieChart(seriesData) {
  const pieData = {
    labels: [
      'Red',
      'Blue',
      'Yellow'
    ],
    datasets: [{
      borderWidth: 1,
      label: 'Expenses',
      data: [300, 50, 100],
      backgroundColor: [
        'rgb(255, 99, 132)',
        'rgb(54, 162, 235)',
        'rgb(255, 205, 86)'
      ],
      hoverOffset: 4
    }]
  }

  const seriesDataNoIgnore = seriesData.filter(s => (s.name !== 'Ignore'))

  pieData.labels = seriesDataNoIgnore.map(s => (s.name))

  pieData.datasets[0].backgroundColor = seriesDataNoIgnore.map(s => (s.color || '#ccc')) // TODO: color based on categoryUuid
  pieData.datasets[0].data = seriesDataNoIgnore.map(s => (s.y || 0))

  return pieData
}
