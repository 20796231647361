import * as React from 'react'
import { useNavigate } from 'react-router'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Link from '@mui/material/Link'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Avatar from '@mui/material/Avatar'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CategoryIcon from '@mui/icons-material/Category'
import CableIcon from '@mui/icons-material/Cable'
import PieChartIcon from '@mui/icons-material/PieChart'

import Copyright from '../../../../components/Copyright'
import HeadingContainer from '../../../../components/HeadingContainer'

import CSVFilePreviewTrainer from '../../../../components/CSVFilePreviewTrainer'

function Tip({text}) {
  return (
    <ListItem sx={{mb: 2}}>
      <ListItemAvatar>
        <Avatar>
          <TipsAndUpdatesIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={text}/>
    </ListItem>
  )
}

function AccordionBlock({
  panelName,
  title,
  expanded,
  handleExpanded,
  children,
  icon
}) {
  return (
    <Accordion expanded={expanded === panelName} onChange={() => handleExpanded(panelName)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box display="flex" alignItems="center">
          {icon || null}
          <Typography variant="h6">
            {title}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        {children}
      </AccordionDetails>
    </Accordion>

  )
}

export default function Help() {
  const navigate = useNavigate()
  const [expanded, setExpanded] = React.useState('')

  const handleExpanded = value => {
    if (value === expanded) return setExpanded('')
    setExpanded(value)
  }

  return (
    <Box>
      <HeadingContainer>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
        >
          Usage Guide
        </Typography>
      </HeadingContainer>

      <Divider />

      <Container maxWidth='md' sx={{mb: 3, pt: 3}}>

        <AccordionBlock
          panelName="intro"
          title="Introduction to Budget Analyser"
          expanded={expanded}
          handleExpanded={handleExpanded}
        >
          <Typography variant="p" component="div" sx={{mb: 2}}>
            Welcome to Budget Analyser, the easiest way to explore your
            spending habits. This tool was created as an alternative to painfully
            categorising transactions in Excel. Begin by exporting a CSV file from
            your bank, and then define categories and rules to quickly sort your
            transactions.
          </Typography>
        </AccordionBlock>

        <AccordionBlock
          panelName="analysis"
          title="Import your bank statement"
          expanded={expanded}
          // icon={<PieChartIcon sx={{color: 'rgba(0, 0, 0, 0.54)', mr: 2}}/>}
          handleExpanded={handleExpanded}
        >
          <Typography variant="p" component="div" sx={{mb: 2}}>
            Budget analyser accepts a bank statement in CSV format.
            CSV stands for "comma-seperated values" and it's a common
            file format for transaction lists.
            For help downloading a CSV export of your transaction history
            from your specific bank, please check out <a href="https://guides.budgetanalyser.com">the guides</a>.
          </Typography>
          <Typography variant="p" component="div" sx={{mb: 2}}>
            To import your CSV, navigate to the <Link onClick={() => navigate('/app/budget/analysis')}>analysis dashboard</Link>,
            and press "Load File".
          </Typography>
          <Typography variant="p" component="div" sx={{mb: 2}}>
            This will open a window that prompts you to select your bank.
            If your bank does not appear in the list, you will need to
            select "custom import options" and configure the fields based on
            the format of your CSV file.
          </Typography>
        </AccordionBlock>

        <AccordionBlock
          panelName="categories"
          title="Create custom categories"
          expanded={expanded}
          // icon={<CategoryIcon sx={{color: 'rgba(0, 0, 0, 0.54)', mr: 2}}/>}
          handleExpanded={handleExpanded}
        >
          <Typography variant="p" component="div" sx={{mb: 2}}>
            Everybody has unique spending habits so Budget Analyser doesn't
            restrict you to preset categories. Navigate to the <Link onClick={() => navigate('/app/budget/categories')}>categories dashboard</Link>,
            to add and edit categories.
          </Typography>
          <Typography variant="p" component="div" sx={{mb: 2}}>
            A category named "Ignore" is special, as transactions in this category are hidden
            from charts. Use this category for things like internal transfers.
          </Typography>
          <List sx={{ width: '100%' }}>
            <Tip text={`Add things such as investments and tax payments to the "Ignore" category, since these aren't daily expenses.`} />
          </List>
        </AccordionBlock>

        <AccordionBlock
          panelName="rules"
          title="Define rules"
          expanded={expanded}
          // icon={<CableIcon sx={{color: 'rgba(0, 0, 0, 0.54)', mr: 2}}/>}
          handleExpanded={handleExpanded}
        >
          <Typography variant="p" component="div" sx={{mb: 2}}>
            The ability to define rules is what makes Budget Analyser so convenient.
            Instead of categorising every individual transaciton, you can define rules that
            match many transactions.
          </Typography>
          <Typography variant="p" component="div" sx={{mb: 2}}>
            For example, I might want to make a rule that puts all transactions made at KFC
            into the "Food" category. To do this, I define a <b>pattern</b> to match transaction descriptions
            using a <b>*</b> character, which matches any number of characters.
          </Typography>
          <Typography variant="p" component="div" sx={{mb: 4}}>
            In the example shown below, the rule <b>KFC*</b> matches multiple different
            KFC transactions shown on the list.
            Change the description pattern and see how the wildcard character works.
          </Typography>

          <CSVFilePreviewTrainer />

          <Typography variant="p" component="div" sx={{mt: 4, mb: 4}}>
            You can manage your rules on the <Link onClick={() => navigate('/app/budget/rules')}>rules dashboard</Link>,
            but it's easiest to create them from the <Link onClick={() => navigate('/app/budget/analysis')}>analysis dashboard</Link>.
          </Typography>


          <List sx={{ width: '100%' }}>
            <Tip text={
              <span>
                The rule <b>COLES*</b> will only match transactions that begin with <b>COLES</b>.
                Use <b>*COLES*</b> to find transactions where <b>COLES</b> is in the middle of the description.
              </span>
            } />
            <Tip text={
              <span>
                Keep your patterns broad but try to avoid overlaps. For example, use <b>*UBER&nbsp;AU*</b> instead
                of <b>*UBER*</b>, because that would also match <b>UBEREATS</b>.
              </span>
            } />
          </List>
        </AccordionBlock>

        <AccordionBlock
          panelName="tips"
          title="Helpful tips"
          expanded={expanded}
          handleExpanded={handleExpanded}
        >
          <List sx={{ width: '100%' }}>
            <Tip text="Limit your CSV history to the past year or two, since it's harder to define mappings over a longer period where your habits have changed." />
          </List>
        </AccordionBlock>
      </Container>

      <Copyright sx={{ pt: 4, mt: 10 }} />
    </Box>
  )
}
