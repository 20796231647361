import { createTheme } from '@mui/material/styles'

// https://bareynol.github.io/mui-theme-creator/

const mdTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#6501de',
    },
    secondary: {
      main: '#ff5770',
    },
  },
})

export default mdTheme
