const stackedColumnChartJSOptions = {
  plugins: {
    title: {
      display: false,
    },
    datalabels: {
      display: false
    },
    tooltip: {
      callbacks: {
        label: tooltipItem => (`${tooltipItem.dataset.label} $${tooltipItem.formattedValue}`)
      }
    }
  },
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
      ticks: {
          // Include a dollar sign in the ticks
          callback: value => (`$${value}`)
      }
    }
  }
}

export default stackedColumnChartJSOptions
